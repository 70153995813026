@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  @include commonText(500);
  color: $textBlack;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  white-space: nowrap;

  &__icon {
    margin-right: 17px;
  }

  &__filters-amount {
    margin-left: 6px;
  }

  @include desktop {
    margin-bottom: 15px;
  }
}

.filters-list {
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 25px 0;
  white-space: nowrap;

  & > :first-child {
    padding-top: 23px;
  }

  @include desktop {
    gap: 15px 0;

    & > :first-child {
      padding-top: 0;
    }
  }
}
