$breakpoints: (
  "mobile": 420px,
  "tablet": 810px,
  "desktop": 1024px,
  "largeDesktop": 1440px,
);

@mixin mobile {
  @media (min-width: map-get($breakpoints, "mobile")) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: map-get($breakpoints, "tablet")) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: map-get($breakpoints, "desktop")) {
    @content;
  }
}
@mixin largeDesktop {
  @media (min-width: map-get($breakpoints, "largeDesktop")) {
    @content;
  }
}

@mixin breakpoint($bp: 0) {
  @media (max-width: $bp) {
    @content;
  }
}
