@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.notification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 40px;

    width: 100%;
    padding: 58px 0;

    text-align: center;
    background-color: $white;
    border-radius: 10px;


    @include desktop {
        grid-row: 1;
        grid-column: span 2;
        margin-bottom: -20px;
    }

    &__title {
        @include customizableText(500, 18px, 22px);
        color: $textBlack;
        border-bottom: 1px solid $textBlack;
        padding-bottom: 15px;

        @include mobile {
            @include additionalText;
        }
        
        @include tablet {
            max-width: 700px;
            width: 100%;
        }
        
        @include desktop {
            max-width: 960px;
        }
        
    }

    &__message-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        color: $textLightGrey;
        @include customizableText(500, 24px, 30px);
    }
}

.primary {
    color: $primary;
}