@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 20px;

  @media (min-width: 530px) and (max-width: 1023px) {
    width: 100%;
  }

  @include desktop {
    width: 100%;
  }
}

.main-title {
  @include additionalText();
  color: $textBlack;

  &__value {
    color: $primary;
  }

  @include desktop {
    margin: 0;
    margin-top: 75px;
    margin-bottom: 54px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    width: 100%;
    margin-bottom: 40px;

    .input__wrapper {
      position: relative;
    }
  }

  &__title {
    @include additionalText;
    color: $textBlack;

    margin-bottom: 40px;
  }

  &__delete-button {
    display: flex;
    align-items: center;
    @include commonText(500);
    color: $textBlack;
    justify-self: flex-end;

    border-radius: 7px;
    border: 1px solid $textLightGrey;
    padding: 7px 16px;

  }

  &__input {
    @include commonText(500);
    color: $textBlack;
    text-align: center;

    width: 100%;

    border-bottom: 1px solid $textLightGrey;
    padding-bottom: 8px;

    &::placeholder {
      text-align: center;
    }

    &:focus-visible {
      background: transparent;
    }
  }

  &__title-input {
    max-width: 260px;
    width: 100%;

    text-align: center;
  }

  &__inputs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    gap: 40px 0;

    & > input {
      text-align: center;
    }

    :first-child {
      max-width: 194px;
    }
  }

  &__adress-additional {
    max-width: 81px;
    width: 100%;

    margin-left: 5px;

    &:placeholder-shown {
      text-overflow: ellipsis;
    }
  }

  @include desktop {
    &__row {
      display: grid;
      grid-template-columns: minmax(150px, 360px) 1fr;

      margin-bottom: 80px;
    }

    &__delete-button {
      justify-self: flex-end;
  
      border-radius: 0;
      border: 0;
      // border-bottom: 1px solid $textLightGrey;
      padding-bottom: 2px;

      margin: 0;
      margin-top: 75px;
      margin-bottom: 54px;

      &::before {
        content: url("../../../../../assets/icons/png/delete-icon.png");
        display: inline-block;
        margin-right: 8px;
      }
    }

    &__title {
      @include commonText(500);
      margin-bottom: 0;
    }

    
    &__input {
      text-align: start;
    }

    &__inputs-wrapper {
      flex-direction: row;
      gap: 0;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  &__title-add-adress {
    @include additionalText();
    color: $primary;
    padding: 10px 0;
    white-space: nowrap;
    flex-grow: 1;
  }

  &__add-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-self: flex-start;

    max-width: 300px;
    width: 100%;
    border: 1px solid $primary;
    border-radius: 5px;
    padding: 5px;
  }

  &__plus-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 14px;
    height: 14px;
    margin-left: 5px;
    flex-shrink: 0;
  }

  &__plus-line-vertical {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: $primary;
    transform: translateX(-50%);
  }

  &__plus-line-horisontal {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $primary;
    transform: translateY(-50%);
  }

  &__save-button {
    grid-column: 2;
    width: 100%;

    &--disabled {
      background-color: $darkgrey;
      cursor: not-allowed;
    }
  }

  

  @include desktop {
    display: grid;
    grid-template-columns: minmax(150px, 360px) 1fr;
  }
}

.cities-wrapper {
  position: absolute;
  min-height: 0;
  overflow: hidden;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
}

.cities {
  overflow: auto;
  scrollbar-width: thin;
  display: grid;
  width: 100%;
  max-height: 200px;
  gap: 9px;

  &::-webkit-scrollbar {
    width: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    @include commonText(500);
    color: $textBlack;
    text-align: start;

    &--region {
      @include descriptionTextMedium(400);
      color: $lightGrey;
    }

    &--result {
      @include descriptionTextMedium(400);
      color: $lightGrey;
      text-align: center;
      padding: 10px;
    }
  }
}

.suggestions-wrapper {
  position: absolute;
  top: 85%;
  min-height: 0;
  overflow: hidden;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  padding: 2px;
}

.suggestions {
  overflow: auto;
  scrollbar-width: thin;
  display: grid;
  width: 100%;
  max-height: 100px;
  gap: 9px;

  &::-webkit-scrollbar {
    width: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    @include commonText(500);
    color: $textBlack;
    text-align: start;

    &--result {
      @include descriptionTextMedium(400);
      color: $lightGrey;
      width: 100%;
      text-align: center;
      padding: 10px 0;
    }
  }
}

