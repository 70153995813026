@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  max-width: 645px;
  width: 100%;
  height: 100%;

  background-color: $darkWhite;

  color: $textBlack;

  border-radius: 10px;
  padding: 30px 30px 30px 33px;
}

.product {
  &_select-title {
    @include commonText(500);
    color: $textLighterGrey;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    position: relative;

    border-bottom: 1px solid $textLighterGrey;

    &__cheap {
      display: flex;
      justify-content: center;
      align-items: center;

      &_icon {
        margin-right: 11px;
      }

      &_title {
        @include customizableText(500, 13px, 16px);
        color: $textLighterGrey;
      }
    }

    &__cart {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include commonText(500);
      color: $white;

      width: 100%;

      background-color: $primary;

      margin: 30px 0;
      border-radius: 5px;
      padding: 12px 48px;

      &-disabled {
        opacity: 0.5;
      }

      &--title {
        white-space: nowrap;
        margin-right: 15px;
      }

      &_price {
        &--promo {
          margin-right: 20px;
          white-space: nowrap;

          @include desktop {
            margin-right: 10px;
          }
          
          @include largeDesktop {
            margin-right: 20px;
          }
        }

        &--main {
          color: $textBlack;
          text-decoration: line-through;
          white-space: nowrap;
        }
      }

      @include desktop {
        padding: 12px 28px;
      }

      @include largeDesktop {
        padding: 12px 48px;
      }
    }
  }

  &__available {
    margin-top: 20px;
    @include commonText(500);
  }
}
