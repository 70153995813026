@import "../../../../../styles/scss/variables";
@import "../../../../../styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  max-width: 100%;
  background-color: $white;
  padding: 10px 25px;


  @include desktop {
    width: 30%;
    max-width: 608px;
    min-width: 300px;
  }
}

.title {
  margin-top: 20px;
  @include customizableText(500, 20px, 24px);
  color: $textBlack;
  white-space: nowrap;
}

.input-wrapper {
  position: relative;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.input {
  width: 100%;
  padding: 10px;
  padding-right: 5px;
  background-color: $darkWhite;
  border-radius: 5px;

  @include commonText(500);
  color: $textBlack;

  &:focus::placeholder {
    color: transparent;
  }
}

.search-icon {
  position: absolute;
  padding: 8px;
  width: auto;
  height: 100%;
  right: 0px;
  top: 0px;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-around;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  &__pickup {
    width: 300px;
    border-radius: 5px;
    padding: 10px 12px;
  }

  &__delivery {
    width: 300px;
    border-radius: 5px;
    margin-left: 20px;
    padding: 10px 12px;
  }

  &--active {
    background-color: $primary;
    border: 1px solid $primary;
  }
}

.provider {
  &-wrapper {
    margin-top: 30px;
  }

  &_item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    

    &--text {
      @include commonText(500);
      margin-left: 10px;
    }

    &--online-payment {
      @include customizableText(200, 12px, 14px);
      margin-left: 10px;
      color: $textLighterGrey;

      @include tablet {
        @include customizableText(200, 14px, 18px);
      }
    }
  }
}

.text-disabled {
  color: $textLighterGrey;
}

.list-wrapper {
  overflow: auto;
  height: calc(100% - 300px);

  @media (min-width: 320px ) and (max-width: 1023px) {
    height: calc(100vh - 300px);
    padding-bottom: 60px;
  }
}

.city {
  &__button {
    margin-top: 15px;
    color: $primary;
    @include descriptionTextBig;
    border-bottom: 2px solid $primary;
  }
}
