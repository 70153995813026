@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: calc(100% - 40px);
  width: 100%;

  margin-bottom: 40px;
}

.dots {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: calc(100% - 40px - 20px - 104px);
  width: 100%;
}

.dot {
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background-color: $secondary;

  &--active {
    background-color: $primary;
  }

  &--small {
    width: 10px;
    height: 10px;
  }
}

.slider-dots {
  align-self: center !important;
}
