@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include commonText(500);
  color: $textBlack;

  width: 100%;
}

.price-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;

  &__min-price, &__max-price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &_text {
      margin-right: 5px;
      white-space: nowrap;
    }

    &_input {
      flex-grow: 1;
      min-width: 50px;
      max-width: 100px;
      margin-right: 5px;
      border: 1px solid $textLighterGrey;
      border-radius: 5px;
      padding: 6px 4px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.slider {
  max-width: 232px;
  width: 100%;
  height: 3px;
  margin-top: 25px;
  margin-bottom: 10px;

  @include desktop {
    margin-top: 40px;
  }
}

.slider_thumb {
  top: -10px;
  width: 23px;
  height: 23px;

  background-color: $primary;

  border-radius: 50%;

  user-select: none;
  cursor: grab;
}

.slider_track {
  height: 3px;
  background-color: $primary;
  opacity: 0.5;

  &:nth-child(2) {
    opacity: 1;
  }
}

.price-range {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 232px;
  width: 100%;
  @include commonText(500);
  color: $textBlack;

  @include desktop {
    margin-bottom: 70px;
  }
}