@import "../../../styles/scss/variables";
@import "../../../styles/scss/mixins/typography";

.root {
  position: relative;

  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;

  border: 2px solid $textLighterGrey;
  border-radius: 5px;

  user-select: none;
  cursor: pointer;

  & > img {
    width: 12px;
    height: 12px;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  .disabled {
    cursor: not-allowed;
  }
}