@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 57px;

  background-color: $darkWhite;
  z-index: 999;
}

.navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  max-width: 1537px;
  width: 100%;
  padding: 0 15px;

  &__list {
    display: flex;
    align-items: center;
    justify-items: start;
    flex-wrap: nowrap;
    gap: 42px;

    max-width: 314px;
    width: 100%;

    list-style: none;
  }

  .list {
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
    }

    &__item-btn {
      @include commonText(500);
      color: $lighterBlack;

      border-bottom: 1px solid $lighterBlack;
    }
  }
}
