@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 177px;
    margin-bottom: 73px;
  }
}

.root {
  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    padding: 0 100px;
    margin-bottom: 180px;
  }
}

.title {
  @include customizableText(500, 18px, 30px);
  margin-bottom: 20px;
  color: $primary;

  @include tablet {
    @include customizableText(500, 20px, 30px);
  }

  @include desktop {
    @include customizableText(500, 24px, 38px);
    margin-bottom: 25px;
  }
}

.text {
  @include customizableText(400, 14px, 21px);
  margin-bottom: 10px;

  @include tablet {
    @include customizableText(400, 16px, 24px);
  }

  @include desktop {
    @include customizableText(400, 18px, 29px);
    margin-bottom: 20px;
  }

  > p {
    margin-bottom: 25px;
  }
  &__list {
    padding-left: 20px;
    margin-bottom: 48px;
    > li {
      list-style: disc;
      margin-bottom: 25px;

      &::marker {
        font-size: 0.5em;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(5) {
    margin-bottom: 93px;
  }

  &__line_break {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -46px;
      left: 0;
      width: 16px;
      height: 1px;
      background-color: black;
    }
  }

  &_link {
    color: $primary;

    margin-left: 5px;

    text-decoration: underline !important;
  }

  &:last-child {
    font-weight: 500;
  }
}

.item_link,
.item_pink {
  color: $primary;
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
