@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.title {
  @include customizableText(500, 18px, 21px);
  margin: 0;
  margin-bottom: 20px;
  color: $primary;

  @include tablet {
    @include customizableText(500, 20px, 24px);
  }

  @include desktop {
    @include customizableText(500, 24px, 29px);
    margin-bottom: 42px;
  }
}

.list {
  @include customizableText(400, 16px, 21px);
  padding-left: 15px;
  
  @include tablet {
    @include customizableText(400, 18px, 24px);
  }

  @include desktop {
    @include customizableText(400, 18px, 29px);
  }

  &__item {
    margin-bottom: 42px;
    
    &__title {
      :first-child {
        @include customizableText(500, 16px, 21px);
      
        @include tablet {
          @include customizableText(500, 18px, 24px);
        }
      
        @include desktop {
          @include customizableText(500, 18px, 29px);
        }
      }
    }

    &__text {
      @include customizableText(400, 14px, 21px);
      padding: 5px 0;
      margin-bottom: 10px;
  
      @include tablet {
        @include customizableText(400, 16px, 24px);
      }
    
      @include desktop {
        @include customizableText(400, 18px, 29px);
        text-align: justify;

        margin-bottom: 20px;
        margin-left: -20px;
      }

      p > span:first-child {
        @include customizableText(500, 14px, 21px);

        @include tablet {
          @include customizableText(500, 16px, 24px);
        }

        @include desktop {
          @include customizableText(500, 18px, 29px);
        }
      }

      &_postscript {
        @include customizableText(500, 14px, 21px);
        margin-left: -15px;
        span:last-child {
          margin-left: -15px;
        }

        @include desktop {
          @include customizableText(500, 18px, 29px);
        }

      }
    }
  }
}

