@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";

.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 95px;
  background-color: transparent;

  margin-bottom: 14px;
  border-radius: 10px;
  border: 1px solid $textLighterGrey;
  cursor: not-allowed;
}
