@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 0 20px;

  @include desktop {
    display: grid;
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-start;

    max-width: 375px;

    margin-left: 10px;
    padding: 0;
  }
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  &__status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 40px;
    gap: 15px 0;
  }

  &__payment-method {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__price {
    margin-bottom: 15px;

    &--black {
      color: $textBlack;
    }
  }

  &:first-child {
    margin-bottom: 40px;
  }

  @include desktop {
    align-items: flex-start;

    &__status {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
  
      gap: 0;
    }

    &__payment-method {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &:last-child {
      align-self: flex-end;
    }
  }
}

.button {
  max-width: 253px;
  width: 100%;
  margin-top: 25px;

  @include desktop {
    margin-top: 19px;
  }
}