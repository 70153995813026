@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 77px;
  gap: 60px;

  @media (min-width: 451px) and (max-width: 809px) {
    margin-top: 0px;
  }

  @include tablet {
    margin-top: 0px;
  }
}

.divider {
  width: calc(100% - 40px);
  background-color: $gainsboro;
  height: 2px;
  border: 0;

  @include tablet {
    width: calc(100% - 80px);
  }

  @media (min-width: 1520px) {
    width: 1520px;
  }
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}