@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.ordered-products {
    order: 1;
    width: 100%;

    @include desktop {
        order: 2;
    }

    &__wrapper {
        width: 100%;
        background-color: $white;
        border-radius: 10px;
        padding: 25px;
    }

    &__title-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__title {
        text-align: center;
        @include additionalText;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid $textLighterGrey;
    }
}

.wrapper {
    position: relative;
    margin: 36px 0;
    margin-bottom: 45px;
    overflow: hidden;
    z-index: 2;
    width: 100%;
}

.products-wrapper {
    position: relative;
    width: 100%;
  
    &--top-shadow {
      &::before {
        content: "";
        position: absolute;
        left: 0;
  
        width: 100%;
        height: 10px;
  
        background: rgba(129, 129, 129, 0.79);
        filter: blur(20px);
        z-index: 1;
      }
    }
    &--bottom-shadow {
      &::after {
        content: "";
        position: absolute;
        left: 0;
  
        width: 100%;
        height: 30px;
  
        background: rgba(129, 129, 129, 0.79);
        filter: blur(20px);
        z-index: 1;
      }
    }
}

.products {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 380px;
    height: 100%;
    gap: 20px;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @include desktop {
        padding-right: 41px;
    }

    &__title {
        @include commonText(500);
        color: $textBlack;
        text-align: center;
        transform: translateX(20px);
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    & > :last-child {
        padding: 0;
    }
}

.line {
    border-bottom: 1px solid $textLighterGrey;
    margin-bottom: 30px;
    height: 1px;
    width: 100%;
}

.order-item {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    text-align: center;
    min-height: 270px;

    @media (min-width: 600px) {
        grid-template-columns: 100px 1fr;
        gap: 0;
        min-height: 120px;
    }

    @include tablet {
        grid-template-columns: 100px 2fr 1fr;
    }

    @include desktop {
        grid-template-columns: 100px 1fr;
        min-height: 200px;
    }

    &__image {
        grid-row: 1 / span 2;
        width: 100%;
        height: auto;
    }
}

.link {
    position: relative;
    color: $primary;
    @include commonText(500);

    @include largeDesktop {
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: -2px;
            left: 0;
            background-color: $primary;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }

        &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }
}