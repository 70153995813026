@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: calc(100% - 20px);

  border-bottom: 1px solid $textBlack;
  padding-bottom: 15px;

  @include desktop {
    max-width: calc(100% - 60px);
  }

  @media (min-width: 1100px) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  min-height: 40px;

  &__select-button {
    @include customizableText(500, 12px, 14px);
    color: $textLightGrey;

    margin-left: 24px;
  }

  &__delete-button {
    @include customizableText(500, 12px, 14px);
    color: $fireIsland;

    margin-left: 35px;
  }

  @include desktop {
    &__select-button {
      white-space: nowrap;
      align-self: auto;
      margin-left: 30px;
    }

    &__delete-button {
      white-space: nowrap;
      margin-left: 28px;
    }
  }

  @include largeDesktop {
    &__select-button {
      margin-left: 40px;
    }

    &__delete-button {
      margin-left: 40px;
    }
  }
}

.title {
  @include additionalText();
  color: $textBlack;
  white-space: nowrap;

  margin-bottom: 30px;

  @media (min-width: 1100px) {
    margin-bottom: 0;
  }
}

.modal {
  &_title {
    text-align: center;
    width: 100%;
    @include customizableText(500, 14px, 16px);

    @include tablet {
      width: 260px;
    }
  }

  &_button-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    &_button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 30px;
      margin-right: 10px;
      @include customizableText(500, 12px, 12px);

      &--primary {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}
