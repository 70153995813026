@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f2f4f7;
  max-width: 400px;
  max-height: 320px;
  padding: 30px 25px;
  border-radius: 30px;
  gap: 20px;

  @include tablet {
    max-height: 280px;
    padding: 60px 50px;
  }
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px
}

.title {
  @include customizableText(500, 20px, auto);
  color: $textBlack;
  color: #070811;
}

.description {
  @include customizableText(400, 14px, auto);
  color: #7f81a0;
  text-align: left;
  padding: 0;

  @include desktop {
    @include customizableText(400, 16px, auto);
  }
}