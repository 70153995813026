@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include mobile{
    flex-direction: row;
    gap: 30px;
  }
  @include tablet{
    flex-direction: row;
  }
  @include desktop {
    flex-direction: column;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 50px;

  @include mobile{
    margin-bottom: 25px;
  }

  @include tablet{
    margin-bottom: 30px;
  }

  @include desktop{
    margin-bottom: 50px;
  }

  &:last-child {
    gap: 18px 0;

    @media (max-width: 620px) {
      gap: 15px 0;
    }
  }
  &__display-none {
    @media (max-width: 620px) {
      display: none;
    }
  }
}


.text {
  @include additionalText;
  color: $textBlack;
  white-space: nowrap;

  @include mobile{
    @include customizableText(500, 18px, 24px)
  }
  @include tablet{
    @include customizableText(500, 18px, 24px);
  }
  @include desktop{
    @include additionalText;
  }

  &--selected {
    color: $primary;
  }

  &--disabled {
    color: $textLightestGrey;
    cursor: not-allowed;
  }
}

.letter {
  @include customizableText(500, 80px, 80px);
  color: $primary;
  text-align: center;
  align-self: center;

  margin-top: 20px;
}