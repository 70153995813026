@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 810px;
  width: 100%;

  margin: 0 auto;

  @include desktop {
    max-width: 910px;
    padding: 90px 0;
  }

  @include largeDesktop {
    max-width: 1300px;
  }
}

.rewiews {
  display: block;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  overflow: hidden;

  @include tablet {
    max-width: 910px;
    height: 500px;
  }

  @include desktop {
    max-width: 1024px;
  }

  @include largeDesktop {
    max-width: 1150px;
  }
}

.content {
  align-items: center;

  width: 100%;
  height: 380px;

  overflow: visible !important;

  @include tablet {
    max-width: 680px;
    height: 400px;
  }

  @include desktop {
    max-width: 750px;
    height: 500px;
  }

  @include largeDesktop {
    max-width: 1050px;
  }
}

.pagination {
  max-width: 471px;
  width: 100%;
  align-self: center;
}