@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 15px;

  background-color: $white;
  z-index: 100;
}

.avatar {
  width: 55px;
  height: 55px;
  margin-bottom: 15px;
}

.user-data {
  @include additionalText();
  color: $textBlack;
}

.title {
  @include additionalText();
  color: $textBlack;
  margin-top: 30px;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  max-width: 348px;
  width: 100%;

  margin-top: 30px;
}

.button {
  max-width: 163px;
  width: 100%;
  height: 52px;
}
