@import "../../../styles/scss/variables";
@import "../../../styles/scss/mixins/typography";

.root {
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  overflow: hidden;
  z-index: 99;
}

.background {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);

  z-index: -1;
}

.personal-area__navigation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 334px;

  border-radius: 10px;
  padding: 38px 42px;

  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
}