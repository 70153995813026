@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.products-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 1224px;
  width: 100%; 
}

.title {
  @include additionalText();
  color: $textBlack;

  margin-top: 40px;

  @include desktop {
    margin-top: 75px;
  }
}

.loading {
  height: 368px;

  &__title {
    @include additionalText;
  }
}