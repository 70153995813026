@import "../../../../../../styles/scss//variables";
@import "../../../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: 0 auto;
  padding-top: 70px;
}

.title {
  @include bigTitle();
  margin: 56px 0 19px;
}

.description {
  @include additionalText();
  color: $sonicSilver;
  margin-bottom: 18px;
}

.review-number {
  @include customizableText(500, 64px, 78px);
  margin-bottom: 18px;
}

.reviews {
  margin-top: 20px;
}

.button-wrapper {
  max-width: 1020px;
  width: 100%;
  align-self: flex-start;

  margin: 0 auto;
  margin-top: -17px;
}

.star-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 150px;
  width: 100%;

  & > svg {
    cursor: pointer;
  }
}