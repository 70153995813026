@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    padding: 0 10px;

    @media (min-width: 360px) {
      gap: 20px;
      padding: 0 20px;
    }

    @include tablet {
      padding: 0 40px;
    }

    @include largeDesktop {
      padding: 0 20px;
      max-width: 1520px;
    }

    @media (min-width: 1520px) {
      padding: 0;
      max-width: 1520px;
    }
  }