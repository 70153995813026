@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  position: relative;

  width: 100%;
  margin-bottom: 40px;
}

.input {
  width: 100%;

  @include commonText(500);
  color: $textLightGrey;
  background-color: $concrete;

  text-align: center;

  border-radius: 5px;
  padding: 16px 23px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px $concrete;
  }

  &--error {
    border: 1px solid $primary;
  }

  @include desktop {
    text-align: start;
  }
}

.error-message {
  position: absolute;
  transform: translateY(-100%);
  top: -4px;
  color: $primary;

  @include descriptionTextMedium(500);
}

.show-password {
  position: absolute;
  top: 50%;
  right: 10px;

  @include customizableText(500, 12px, 12px);
  color: $textLightGrey;
  padding: 5px;

  transform: translateY(-50%);
}