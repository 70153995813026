@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1261px;
  width: 100%;

  margin-top: 75px;

  :nth-child(2) {
    border-top: 0;
  }

  @include desktop {
    align-items: flex-start;
  }
}

.title {
  @include additionalText();
  color: $textBlack;
  margin-bottom: 23px;

  @include desktop {
    margin-bottom: 58px;
  }
}