@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
}

// .tab {
//   &__button {
//     display: flex;
//     justify-content: center;
//     align-items: center;
    
//     width: 35px;
//     height: 35px;

//     -webkit-tap-highlight-color: transparent;
//   }
// }