@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  padding: 12px 30px 17px;
}

.list {

  &__item {
    list-style-type: none;
    padding-bottom: 4px;
    @include commonText(500);
    color: $textBlack;

    &:hover {
      color: $primary;
    }
  }
}
