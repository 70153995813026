@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.order-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;

    @include tablet {
        padding: 30px 50px;
    }
  
    &__title {
        text-align: center;
        @include additionalText;
        padding-bottom: 10px;
        border-bottom: 1px solid $textLighterGrey;
    }

    &__order-date {
        margin-top: 10px;
        color: $textLightGrey;
        @include customizableText(500, 24px, 28px);
    }

    &__payment-button {
        text-decoration: underline !important;
        text-align: center;
        color: $primary;
        cursor: pointer;

        @include customizableText(500, 24px, 28px);
    }

    &__text {
        @include additionalText;
        color: $textLightGrey;
    }
}

.primary {
    color: $primary;
}

.empty-block {
    height: 28px;
}