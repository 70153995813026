@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.recipient-data {
    width: 100%;
    height: auto;
    background-color: $white;
    border-radius: 10px;
    padding: 25px;
    order: 2;

    @include desktop {
        order: 1;
    }

    &__title {
      text-align: center;
      @include additionalText;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid $textLighterGrey;
    }

    &__wrapper-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 40px;
    }

    &__button {
        width: 100%;
    }
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    margin-bottom: 20px;

    @include tablet {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 40px;
    }
}

.payment-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    margin-bottom: 20px;

    @media (min-width: 500px) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    @include tablet {
        justify-content: flex-start;
        margin-bottom: 40px;
    }
}

.line {
    border-bottom: 1px solid $textLighterGrey;
    margin-bottom: 30px;
    height: 1px;
    width: 100%;
}