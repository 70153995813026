@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 950px;
  width: 100%;

  padding: 50px 20px 0;

  @include desktop {
    margin-top: 63px;
    padding: 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;
  margin-bottom: 40px;

  &__title {
    @include additionalText;
    color: $textBlack;

    margin-bottom: 40px;
  }

  &__input-wrapper {
    position: relative;
    width: 100%;
  }

  &__input {
    @include commonText(500);
    color: $textBlack;
    text-align: center;

    width: 100%;

    border-bottom: 1px solid $textLightGrey;
    padding-bottom: 8px;

    &_phone {
      color: $grey;
    }
  }

  &__required-symbol {
    position: absolute;
    top: 0;
    right: 0;
    color: $primary;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include desktop {
    display: grid;
    grid-template-columns: minmax(150px, 360px) 1fr;

    margin-bottom: 80px;

    &__title {
      @include commonText(500);
      margin-bottom: 0;
    }

    &__input {
      text-align: start;
    }

    &__required-symbol {
      top: 6px;
    }
  }
}

.gender {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 280px;
  width: 100%;

  &__button {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:first-child {
      margin-right: 10px;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__indicator {
    position: relative;
    width: 18px;
    height: 18px;

    border: 2px solid $textLightGrey;
    border-radius: 100%;

    &--active {
      width: 10px;
      height: 10px;

      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $primary;
      border-radius: 100%;
      transform: translate(-50%, -50%);
    }
  }

  &__title {
    @include commonText(500);
    color: $textBlack;

    margin-left: 20px;
  }
}


.save-button {
  grid-column: 2;
  margin-top: 10px;
  width: 100%;

  @include desktop {
    margin-top: 0;
  }
}

.save-button--disabled {
  background-color: $darkgrey;
  cursor: not-allowed;
}

.selected-day {
  background-color: $primary !important;
}
