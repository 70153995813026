@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.modal {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include desktop {
      padding: 15px;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      padding: 42px 30px;
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    }

    &_logo-wrapper {
      width: 100%;
      display: flex;
      position: relative;

      &-svg {
        height: 55px;
      }

      &_btn {
        position: absolute;
        right: 0;
        top: 0;

        &-close {
          width: 0px;

          @include desktop {
            width: 29px;
          }
        }
      }
    }
    &_text-wrapper {
      margin-top: 20px;
      @include commonText(500);

      & p {
        @include desktop {
          width: 550px;
        }
      }
    }
  }
}
