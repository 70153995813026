@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-title {
  @include customizableText(500, 14px, 17px);
  color: $sonicSilver;
  margin: 25px 0;
}

.empty-title {
  margin: 25px 0;
}

.product-image {
  max-width: 145px;
  max-height: 400px;
  margin-bottom: 25px;

  &-disabled {
    width: 0px;
    min-width: 0px;
  }

  &-svg {
    max-width: 145px;
    max-height: 400px;
    width: 90%;
    height: 90%;
    opacity: 0.3;
    animation: 2s animation linear infinite;
  }

  @keyframes animation {
    0% {
      opacity: 0.3;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 0.3;
    }
  }

  @media (min-width: 450px) {
    min-width: 250px;
    max-width: 300px;
    max-height: 600px;
  }
}

.button-cheap {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 25px 0;

  &__icon {
    width: 19px;
    height: 19px;

    margin-right: 11px;
  }

  &__title {
    @include customizableText(500, 13px, 16px);
    color: $textLighterGrey;
  }
}

.button-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include commonText(500);
  color: $white;

  max-width: 350px;
  width: 100%;

  background-color: $primary;

  border-radius: 5px;
  padding: 14px 10px;

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--title {
    white-space: nowrap;
    @include customizableText(500, 12px, 14px);
    min-width: 0;
    max-width: 100%;

    @include mobile {
      @include customizableText(500, 14px, 16px);
    }
  }

  &--no-price {
    display: block;
    text-align: center;
    width: 100%;
  }

  &__price {
    min-width: 0;
    max-width: 100%;

    &--promo {
      @include customizableText(500, 12px, 14px);
      white-space: nowrap;
      margin-right: 0px;
      
      @include mobile {
        @include customizableText(500, 14px, 16px);
        margin-right: 10px;
      }
    }

    &--main {
      @include customizableText(500, 12px, 14px);
      color: $textBlack;
      text-decoration: line-through;
      white-space: nowrap;

      @include mobile {
        @include customizableText(500, 14px, 16px);
      }
    }
  }

  @include mobile {
    padding: 14px;
  }

}

.dropdowns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding-top: 20px;
}

.vendor-code {
  @include customizableText(500, 14px, 16px);
  margin-bottom: 15px;
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
