@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.filters-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  overflow: hidden;

  &-enter-active {
    max-height: 0px;
  }

  &-enter-done {
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

  &-exit-active { 
    max-height: 300px;
  }

  &-exit-done {
    max-height: 0px;
    transition: max-height 0.5s ease;
  }
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  max-height: 245px;
  width: 100%;
  height: 100%;

  overflow-y: auto;
  -webkit-transform: translate3d(0, 0, 0);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $lightSilver;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $textBlack;
    color: $textBlack;
  }
}

.error {
  align-self: center;
  padding: 4px 0 12px;
}

.show-more__button {
  color: $primary;
  width: 100%;
  padding: 10px 0;
}

.search {
  display: none;

  @include tablet {
    display: flex;
  }
}