@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.image {
  width: 100%;
  transition: filter 0.3s ease-in-out;
}

.loading {
  filter: blur(5px);
}

.loaded {
  filter: blur(0);
}