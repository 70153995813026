@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.item {
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    text-decoration: none;

    @include tablet {
      width: 230px;
      height: 230px;
      border-radius: 16px;
    }

    @include desktop {
      width: 280px;
      height: 280px;
      border-radius: 20px;
    }
    
    &:hover,
    &.active {
      box-shadow: 0 4px 8px rgba(25, 27, 56, 0.2);
      -webkit-box-shadow: 0 4px 8px rgba(25, 27, 56, 0.2);
      -moz-box-shadow: 0 4px 8px rgba(25, 27, 56, 0.2);
    }
  }
  
  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .title {
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    color: #191B38;
    @include customizableText(700, 16px, 19.5px);
    text-align: left;

    @include tablet {
      @include customizableText(700, 24px, 28px);
      top: 24px;
      left: 24px;
      right: 24px;
    }

    @include desktop {
      @include customizableText(700, 32px, 39px);
      top: 30px;
      left: 30px;
      right: 30px;
    }
  }