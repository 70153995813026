@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 1294px;
  width: 100%;
}

.title {
  @include additionalText();
  color: $textBlack;

  margin: 75px auto 0;

  @include desktop {
    margin: 75px 0 0;
  }
}

.order-item {
  width: 100%;
  margin-top: 75px;
}

.loading {
  height: 368px;

  &__title {
    @include additionalText;
  }
}

.show-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  &__button {
    max-width: 231px;
    width: 100%;

    margin-top: 80px;
    margin-bottom: 45px;
  }

  &__pagination {
    margin-top: 80px;
  }

  @include desktop {
    margin-top: 0;
  }
}
