@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;

  @include tablet {
    padding: 0 40px;
  }

  @include largeDesktop {
    max-width: 1400px;
  }

  @media (min-width: 1520px) {
    padding: 0;
    max-width: 1520px;
  }
}

.title {
  @include customizableText(800, 32px, auto);
  color: $darkBlue;
  margin-bottom: 30px;
  width: 100%;
  align-self: flex-start;

  @include tablet {
    @include customizableText(800, 40px, auto);
    white-space: nowrap;
  }
}

.products {
  display: grid;
  gap: 20px;
  margin-bottom: 30px;
  grid-template-columns: 1fr;

  @media (min-width: 700px) and (max-width: 809px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include desktop {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1340px) and (max-width: 1439px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include largeDesktop {
    gap: 30px;
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1520px) {
    gap: 20px;
    grid-template-columns: repeat(6, 1fr);
  }
}