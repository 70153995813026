@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  position: relative;
  justify-self: flex-start;
}

.content {
  display: grid;
  position: fixed;
  top: 57px;
  left: 0;

  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: 50;

  &-enter-active {
    height: auto;
    grid-template-rows: 0fr;
  }

  &-enter-done {
    grid-template-rows: 1fr;
    transition: grid-template-rows 300ms ease;
  }

  &-exit {
    grid-template-rows: 1fr;
  }

  &-exit-active {
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms ease;
  }

  &-exit-done {
    height: 0;
  }
}

.container {
  min-height: 0;
}

.catalog-container {
  background-color: $white;
  padding: 20px;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.cross-button {
  position: relative;
  width: 29px;
  height: 29px;

  &::after {
    content: "";
    position: absolute;
    top: -13px;
    left: -20px;

    width: 66px;
    height: 59px;

    background-color: $white;
    z-index: -1;
  }
}

.cross-icon {
  filter: brightness(0);
}
