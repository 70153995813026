@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.bottomsheet {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  max-height: 100dvh;
  padding: 0 20px;

  background-color: $white;
  z-index: 999;


  &__content-wrapper {
    display: flex;
    flex-direction: column;

    padding: 20px 0;

    &--full {
      height: 100dvh;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    -webkit-tap-highlight-color: transparent;
  }

  &-enter-active {
    transform: translateY(100%);
  }

  &-enter-done {
    transform: translateY(0);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
    transition: transform 300ms ease;
  }

  &-exit { 
    transform: translateY(0);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  }

  &-exit-done {
    transform: translateY(100%);
    transition: transform 300ms ease;
  }
}

.background {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;

  background-color: transparent;
  backdrop-filter: blur(0px);

  z-index: 500;
  transition: background-color 0.3s ease;

  &--active {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(3px);
  }
}
