@import "../../../../../styles/scss/variables";
@import "../../../../../styles/scss/mixins/typography";

.root {
  height: 100%;
}

.head {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 10px;
}

.content {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.map {
  width: 70vw;
  height: 100%;
  flex-grow: 1;
  display: block;

  &--hidden {
    display: none;
  }
}
