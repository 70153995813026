.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.dropdown {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;

  width:  100%;

  &-wrapper {
    min-height: 0;
    width: 100%;
  }

  &-enter {
    grid-template-rows: 0fr;
  }

  &-enter-done {
    grid-template-rows: 1fr;
    transition: grid-template-rows 0.4s ease;
  }

  &-exit { 
    grid-template-rows: 1fr;
  }

  &-exit-done {
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.4s ease;
  }
}
