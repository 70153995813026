@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  width: 100%;

  margin: 85px auto;

  @include tablet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 900px;

    margin: 45px auto;
    padding: 0 10px;
  }

  @include desktop {
    max-width: 1550px;

    margin: 90px auto;
  }
}

.news-wrapper {
  margin-top: 35px;

  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 70px;
  }
}