@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.slide {
  position: relative;
  width: 100vw !important;
  height: 0;
  padding-bottom: 47.5%;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 1520px;
  border-radius: 20px !important;
  object-position: center;
}

.text-overlay {
  position: absolute;
  top: 40%;
  left: calc(50% - 70px);
  transform: translateY(-50%);
  text-align: center;

  @include tablet {
    left: calc(50% - 120px);
  }

  @include desktop {
    left: calc(50% - 230px);
  }

  @include largeDesktop {
    left: calc(50% - 250px);
  }

  @media (min-width: 1520px) {
    left: 530px;
  }

  &__first-string,
  &__two-string {
    color: $white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__first-string {
    @include customizableYesevaText(36px, 31px);

    @include tablet {
      @include customizableYesevaText(56px, 50px);
    }

    @include desktop {
      @include customizableYesevaText(125px, 85px);
    }
  }

  &__two-string {
    position: relative;
    @include customizableYesevaText(30px, 26px);
    left: 30px;

    @include tablet {
      @include customizableYesevaText(46px, 40px);
      left: 40px;
    }

    @include desktop {
      @include customizableYesevaText(98px, 85px);
      left: 60px;
    }

    @include largeDesktop {
      left: 80px;
    }
  }
}

.decorative-line {
  position: absolute;
  width: 50px;
  height: 2px;
  background: linear-gradient(
    90deg,
    #ffdfaa 0%,
    #a48160 34%,
    #cb9762 57%,
    #ce924f 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  left: 60%;
  transform: translateX(-50%);

  @include tablet {
    width: 80px;
    height: 3px;
  }

  @include desktop {
    width: 150px;
    height: 4px;
  }
}

.decorative-line--top {
  top: -13px;

  @include tablet {
    top: -23px;
  }

  @include desktop {
    top: -43px;
  }
}

.decorative-line--bottom {
  bottom: -23px;

  @include tablet {
    bottom: -43px;
  }

  @include desktop {
    bottom: -63px;
  }
}
