@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1294px;
  width: 100%;
  background-color: $superSilver;

  margin-bottom: 40px;
  padding: 15px 20px;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 75px;
    padding: 19px 28px 19px 19px;
  }
}

.order-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include commonText(500);
  color: $textLightGrey;

  margin-bottom: 25px;;

  &__number {
    color: $primary;
  }

  @include desktop {
    align-items: flex-start;
    margin-bottom: 0;
  }
}

.order-status {
  @include commonText(500);
  color: $textBlack;

  &__status {
    color: $primary;
  }
}