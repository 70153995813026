@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.item {
  &__title {
    color: $textLightGrey;
    margin-right: 15px;
  }

  &__rows-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 15px 0;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    :nth-child(3n + 3) {
      > svg {
        margin-right: 0;
      }
    }
  }

  &__row-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    gap: 19px 0;
  }

  &__value {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.value {
  &__title {
    @include commonText(500);
    color: $textBlack;
    text-align: left;
    margin-right: 10px;
    white-space: nowrap;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 15px;
    height: 15px;
    color: $primary;

    margin-right: 15px;

    & > svg {
      min-width: 8px;
      min-height: 8px;
    }
  }
}
