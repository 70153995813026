@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/typography";

.custom-select {
  position: relative;
  display: inline-block;
  width: 80px;
  margin: 5px;
  border-radius: 5px;

  &:hover {
    background-color: $primary;
  }

  &__box {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1000;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: $lightSilver;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $textBlack;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: darken($textBlack, 10%);
    }
  }

  &__option {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}