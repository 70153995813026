@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.dropdown {
  border-bottom: 1px solid $textLighterGrey;
}

.disable {
  pointer-events: none;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include commonText(500);
  color: $textBlack;

  width: 100%;
  height: 100%;

  padding: 14px 0;

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include desktop {
    justify-content: flex-start;
  }
}

.plus-icon-wrapper {
  position: absolute;
  top: 50%;
  right: 0;

  transform: translateY(-50%);
}

.plus-icon {
  width: 12px;
  height: 12px;

  transform: rotate(0deg);
  transition: transform 0.2s ease;

  &--rotated {
    transform: rotate(-45deg);
  }
}

.table {
  @include customizableText(500, 12px, 13px);
  color: $textBlack;

  width: 100%;

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 20px;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 13px;
    }
  }

  @include desktop {
    padding-right: 22px;

    &__row {
      display: grid;
      grid-template-columns: 35% 65%;
      align-items: center;

      &:first-child {
        padding-top: 28px;
      }

      &:last-child {
        padding-bottom: 41px;
      }
    }
  }
}

.row {
  &__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    @include desktop {
      width: auto;
    }
  }

  &__input {
    max-width: 300px;
    width: 100%;
    text-align: center;
    border: 1px solid $lighterGrey;
    border-radius: 3px;
    padding: 5px;

    &::placeholder {
      text-align: center;
    }
  }

  &__city {
    margin-bottom: 25px;
  }

  &__title {
    @include customizableText(500, 14px, 17px);
    margin-bottom: 15px;

    @include desktop {
      padding-right: 10px;
      margin-bottom: 0px;
    }
  }

  @include desktop {
    &__input {
      max-width: 100%;
    }
  }
}

.table-wrapper {
  display: flex;
  width: 100%;

  &__column {
    &-title {
      @include customizableText(500, 12px, 13px);
      margin-bottom: 10px;
      height: 30px;
    }

    &-right {
      width: 210px;
      min-width: 50px;
    }

    &-price {
      text-align: center;
    }
  }
  &_content {
    width: 90%;
    display: flex;
    justify-content: space-around;
  }

  @include desktop {
    width: 100%;
    grid-template-columns: repeat(5, auto);
    gap: 0;
  }
}
