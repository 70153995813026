@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: 30px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  
  :last-child {
    margin-bottom: 0;
  }
  
  @include tablet {
    margin-bottom: 20px;
    padding: 15px 15px;
    border-radius: 10px;
    
  }

  @include desktop {
    padding: 36px 44px;
  }
}

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;

  margin-bottom: 36px;
  border-bottom: 1px solid $textLighterGrey;
  padding-bottom: 14px;
}

.step {
  position: absolute;
  top: 50%;
  left: 0;

  @include additionalText();
  color: $primary;

  transform: translateY(calc(-50% - 7px));
}

.title {
  @include additionalText();
  color: $textBlack;
}

.data {
  @include commonText(500);
  color: $textBlack;

  width: 100%;
  padding-left: 10px;
  margin-top: 20px;

  &__text {
    padding-bottom: 20px;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  &__checkbox {
    margin-right: 11px;
    border: 1px solid $textLighterGrey;
  }

  &__title {
    color: $primary;
  }

  &-change {
    margin-top: 30px;
  }
}
