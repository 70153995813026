@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.modal {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include desktop {
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      padding: 42px 30px;
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    }

    &_logo-wrapper {
      width: 400px;
      display: flex;
      justify-content: center;
      position: relative;

      &-logo {
        width: 160px;
      }

      &_btn {
        position: absolute;
        right: 0;
        top: 0;

        &-close {
          width: 0px;

          @include desktop {
            width: 29px;
          }
        }
      }
    }

    &_heading-wrapper {
      margin-top: 30px;
      width: 100%;
      text-align: center;

      &-heading {
        @include commonText(500);
      }
    }

    &_input-wrapper {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;

      &-input {
        @include descriptionTextSmall;
        margin-top: 10px;
        margin-bottom: 5px;
        width: 100%;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid $lighterGrey;
      }

      &-comment {
        height: 120px;
        resize: none;
      }

      &-error {
        border: 1px solid $primary;
      }

      &-form-error {
        height: 15px;
        @include descriptionTextSmall;
        color: $primary;
      }
    }

    &-btn-send {
      margin-top: 30px;
      width: 100%;
    }
  }

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(3px);
    z-index: -1;
  }
}
