@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 177px;
    margin-bottom: 73px;
  }
}

.root {
  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    padding: 0 100px;
    margin-bottom: 180px;
  }
}

.text {
  @include customizableText(400, 14px, 21px);
  margin-bottom: 10px;

  @include tablet {
    @include customizableText(400, 16px, 24px);
  }

  @include desktop {
    @include customizableText(400, 18px, 29px);
    margin-bottom: 20px;
  }

  &:first-child {
    font-weight: 500;

    color: $primary;
  }

  &:not(:nth-child(3)) {
    font-weight: 500;
  }

  &_link {
    color: $primary;

    margin-left: 5px;
  }
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
