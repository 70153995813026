@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.list {
  vertical-align: top;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &__item {
    display: inline;
  }
}

.skeleton {
  width: 100%;
  height: 15px;
  margin: 0px;
}

.item {
  &__link {
    @include customizableText(500, 14px, 16px);
    color: $textBlack;
    vertical-align: top;

    &--last {
      color: $primary;
    }
  }

  &__arrow {
    width: 6px;
    height: 12px;
    margin: 0 10px;

    &--rotated {
      transform: rotate(90deg);
      margin: 0 0 0 10px;
    }
  }

  @include desktop {
    &__link {
      @include additionalText();
      vertical-align: baseline;
    }

    &__arrow {
      margin: 0 15px;
    }
  }
}
