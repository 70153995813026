.markdown-button {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;

  &__image {
    object-fit: contain;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
  }

  // Увеличение области клика кнопок для удобства
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
    cursor: pointer;
  }

  &:active {
    animation: scaleDown 0.2s ease forwards;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
