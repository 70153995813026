@import "../../../styles/scss/variables";
@import "../../../styles/scss/mixins/typography";

.main-title {
  @include commonText(500);
  color: $textBlack;
  margin-bottom: 10px;
}

.my-secret-custom-id {
  display: none;
}

.content {
  &__text {
    @include customizableText(500, 14px, 16px);
    color: $textBlack;
    margin-bottom: 5px;
  }

  &__phone {
    @include customizableText(500, 14px, 16px);
    color: $primary;
    margin-bottom: 5px;
  }
}