@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.loading {
  background-color: #ccc;
  border-radius: 20px !important;
  margin: 8px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 200% 200%;
  animation: gradient 3.6s infinite;
}

@keyframes gradient {
  0% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: -100%;
  }
}
