@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  padding: 0 10px;
  padding-top: 20px;

  background-color: $white;
}

.header {
  display: flex;
  width: 100%;
  @include commonText(500);
  color: $lighterBlack;

  flex-direction: column;

  @include mobile {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &_title {
    margin-right: 5px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 20px;
  margin-bottom: 2px;

  @include mobile {
    :first-child {
      width: 149px;
    }
  
    :last-child {
      width: 149px;
    }
  }

}