@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/typography";

.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    display: flex;
    align-items: center;

    position: relative;

    min-width: 32px;
    height: 32px;

    @include commonText(500);
    color: $textBlack;

    text-align: center;

    padding: 0 15px;

    user-select: none;

    &__dots {
      align-self: center;
      user-select: none;
      padding: 0 5px;
    }

    &__dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      cursor: pointer;
    }

    &--selected {
      display: flex;
      align-items: center;
      justify-content: center; 
      box-shadow: none;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
  
        background-color: $lightPink;
  
        width: 41px;
        height: 41px;
  
        border-radius: 50%;
  
        transform: translate(-50%, -50%);

        @media (max-width: 419px) and (min-width: 300px) {
          width: 38px;
          height: 38px;
        }
      }
    }
    @media (max-width: 419px) and (min-width: 300px) {
      padding: 0 8px;
    }
  }
}