@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 1537px;
  width: 100%;

  margin: 57px auto 85px;

  @include desktop {
    margin: 177px auto;
    padding: 0 10px;
  }
}

.title {
  @include additionalText;
  color: $textBlack;
  margin: 50px 0 40px;
  align-self: center;

  @include desktop {
    @include bigTitle;
    margin-bottom: 101px;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 900px;
  width: 100%;

  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__tab {
    @include additionalText();
    color: $textBlack;

    position: relative;

    background-color: inherit;

    border-bottom: 1px solid $textLighterGrey;
    padding-right: 55px;
    padding-bottom: 14px;

    user-select: none;
    outline: none;
    cursor: pointer;

    &--active::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: calc(100% - 55px);
      height: 2px;
      background-color: $textBlack;
    }

    &:last-child {
      padding-right: 0;

      &::before {
        width: 100%;
      }
    }
  }

  :first-child {
    margin-left: 20px;
  }

  :last-child {
    margin-right: 20px;
  }

  @include desktop {
    border-bottom: 1px solid $textLighterGrey;
    gap: 0;

    &__tab {
      border-bottom: 0;
      padding-right: 0;
      padding-bottom: 18px;

      &--active::before {
        width: 100%;
      }
    }

    :first-child {
      margin-left: 0;
    }
  
    :last-child {
      margin-right: 0;
    }
  }
}