@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  background-color: $white;

  border-radius: 10px;
  padding: 15px 10px;

  @include desktop {
    position: sticky;
    justify-content: flex-start;
    height: fit-content;
    top: 80px;
    right: 0;

    padding: 36px 31px 52px;
  }
}

.title-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.title {
  @include additionalText();
  max-width: 400px;
  width: 400px;
  margin-bottom: 40px;
  border-bottom: 1px solid $textBlack;
  padding-bottom: 5px;

  text-align: center;

  @include desktop {
    margin-bottom: 34px;
    padding-bottom: 14px;
  }
}

.amount-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 40px;

  @include desktop {
    align-items: flex-start;
    margin-bottom: 50px;
  }
}

.amount {
  flex-wrap: wrap;
  white-space: nowrap;
  margin-bottom: 15px;
}

.chosen-items {
  flex-wrap: wrap;
  white-space: nowrap;
}

.price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  @include desktop {
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 61px;
  }
}

.price {
  flex-wrap: wrap;
  white-space: nowrap;
  margin-bottom: 15px;
}

.text-black {
  color: $textBlack;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 40px;

  @include desktop {
    margin-top: 61px;
  }
}
