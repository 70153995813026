@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.offer-picker {
  position: relative;
  margin: 25px 0;

  &__list {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-height: 270px;
    height: 100%;

    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &--top-shadow::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 14px;
    background-color: $gainsboro;
    filter: blur(10px);

    z-index: 20;
  }

  &--bottom-shadow::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 14px;
    background-color: $gainsboro;
    filter: blur(10px);

    z-index: 20;
  }
}

.offer--not-available {
  @include additionalText;
  color: $textBlack;
}
