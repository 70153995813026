@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";

.root {
  position: relative;

  width: 100%;

  margin: 85px auto;

  @include desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 1100px;
    margin: 80px auto 60px;
  }

  @include largeDesktop {
    max-width: 1650px;
    margin: 120px auto 110px;
  }
}

.slider-wrapper {
  max-width: 1546px;
  width: 100%;
  overflow: hidden;
}

.slider {
  width: 100%;
  position: static;
}

.content {
  @include tablet {
    max-width: 900px;
    height: 200px;
  }

  @include desktop {
    height: 522px;
    margin: 0 auto;
    z-index: 10;
  
    overflow: visible !important;
  }

  @include largeDesktop {
    max-width: 1360px;
  }
}

.pagination {
  grid-template-columns: auto 138px auto;

  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    padding: 0 20px;
  }

  @include desktop {
    padding: 0 5px;
  }
}