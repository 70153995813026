@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.root {
  width: 100%;
}

.button {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  margin: 2px 0;
  padding: 10.5px 20px 10.5px 24px;
}

.checkbox {
  position: relative;

  min-width: 21px;
  min-height: 21px;

  margin-right: 16px;
  border: 1px solid $textLighterGrey;
  border-radius: 3px;

  user-select: none;
  cursor: pointer;

  & > img {
    width: 17px;
    height: 17px;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
}

.title {
  @include commonText(500);
  color: $textBlack;
  text-align: left;
}

.disabled {
  background-color: $concrete;
  filter: contrast(0.9) grayscale(100%);
  cursor: not-allowed;
}