@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.anchors {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: sticky;
  top: 80px;
  left: 0;

  margin-left: 10px;
  border-left: 2px solid $textLighterGrey;
  padding-left: 32px;

  &__button {
    @include commonText(500);
    color: $textBlack;
    padding: 8px 0;

    & > span {
      white-space: nowrap;
    }
  }

  &__indicator {
    position: absolute;
    top: 0;
    left: -2px;
    width: 2px;
    height: 36px;
    background-color: $textBlack;
  }

  @include desktop {
    display: flex;
  }
}