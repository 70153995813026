@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

$base-color: #ddd;
$shine-color: #e8e8e8;

.filters-skeleton {
    cursor: not-allowed;
    position: relative;

    @include commonText(500);
    color: $textBlack;
    
    width: 100%;
  
    list-style: none;
    margin-bottom: 14px;
    border-radius: 10px;
    border: 1px solid $textLighterGrey;
  
    @include desktop {
      border-radius: 0;
      border: 0;
      margin-bottom: 0;
    }
}

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  
    position: relative;
  
    padding: 10px 24px;
  
    width: 100%;

    @include desktop {
      justify-content: center;
      padding: 14px 0;
    }
}

.skeleton {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: $base-color;
    width: 100%;
    min-height: 1em;
    height: 100%;
    border-radius: 20px;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      
        background-image: linear-gradient(
          90deg,
          $base-color 0px,
          $shine-color 20px,
          $base-color 40px
        );
        background-size: 200% 100%;
        animation: shimmer 2.5s infinite linear;
        content: '';
        z-index: 2;
    }
}

@keyframes shimmer {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
}
