@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: grid;
  grid-template-columns: repeat(2, 0.5fr) minmax(115px, 152px) minmax(auto, 1fr);
  justify-items: center;
  align-items: center;
  gap: 10px;

  position: fixed;
  top: 0;

  width: 100%;
  height: 57px;

  padding: 12px 20px;
  background-color: $darkWhite;
  box-shadow: inset 0px -1px $lighterGrey;
  z-index: 100;
}

.burger-button {
  justify-self: flex-start;
}

.search-icon {
  width: 21px;
  height: 21px;
}

.main-link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__logo {
    height: 20px;
  }
}

.cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-self: flex-end;
  grid-column: 4;

  &__link {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__icon_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }

  &__products-amount {
    position: absolute;
    bottom: 5px;
    left: 50%;

    @include customizableText(500, 12px, 13px);
    color: $textLighterGrey;

    transform: translateX(-50%);
  }

  &__products-price {
    white-space: nowrap;
    @include customizableText(500, 12px, 13px);
    color: $textLighterGrey;

    margin-top: 8px;
    margin-left: 10px;
  }
}
