@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.password-hint {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 5px;
  padding: 6px;
  margin-bottom: 20px;
  transform: translateY(-10px);
  transition: opacity 0.3s, transform 0.3s;

  &__message {
    @include customizableText(500, 10px, 18px);
    color: $textLightestGrey;
  }
}
