.Toastify__toast-body {
  @include customizableText(500, 14px, 20px);
  color: $textBlack;
}

.Toastify__progress-bar {
  background: $primary;
}

.Toastify__toast-container--top-right {
  top: 9em;

  @media (min-width: 1440px) {
    top: 4em !important;
  }
}
