@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  width: 100%;

  background-color: $darkWhite;
  border-top: 1px solid $lighterGrey;

  padding-top: 35px;

  @include desktop {
    padding-top: 45px;
  }
}

.info {
  display: flex;

  @include tablet {
    display: none;
  }

  @media (min-width: 811px) and (max-width: 1023px) {
    display: flex;
  }

  @include desktop {
    display: flex;
  }

  &-mobile {
    display: none;

    @include tablet {
      display: flex;
    }

    @media (min-width: 811px) and (max-width: 1023px) {
      display: none;
    }

    @include desktop {
      display: none;
    }
  }
}

.blocks-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: 0 auto;
  padding: 0 20px 27px;

  @include tablet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    max-width: 1024px;

    padding: 0 36px 15px;

    border-bottom: 1px solid $lighterGrey;
  }

  @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    max-width: 1540px;

    padding: 0 10px 27px;
  }
}

.lists-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  @include tablet {
    align-items: flex-start;
    max-width: 400px;
  }

  @include desktop {
    align-items: flex-start;
    max-width: 1000px;
  }
}

.main-logo {
  width: 127px;
  height: 23px;

  @include desktop {
    width: 245px;
    height: 45px;
  }
}

.links {
  width: 100%;

  margin-top: 35px;
  border-bottom: 1px solid $textBlack;
  padding-bottom: 35px;

  > :last-child {
    margin-bottom: 0;
  }

  @include tablet {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media (min-width: 811px) and (max-width: 1023px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    margin-top: 41px;
    border-bottom: 0;
    padding-bottom: 0;

    > :nth-child(2) {
      padding: 0 10px;
    }

    &__products {
      > div:first-child {
        margin-bottom: 41px;
      }
    }
  }

  @include desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin-top: 41px;
    border-bottom: 0;
    padding-bottom: 0;

    > :nth-child(2) {
      padding: 0 10px;
    }

    &__products {
      > div:first-child {
        margin-bottom: 41px;
      }
    }
  }
}

.сopyright-block_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 20px;

  @include desktop {
    width: 100%;

    margin: 0;
    border-top: 1px solid $lighterGrey;
  }
}

.сopyright-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  border-top: 1px solid $textBlack;
  padding: 39px 0 90px;

  &_symbol {
    font-size: 15px;
    margin-right: 7px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @include customizableText(400, 12px, 15px);
    color: $textBlack;
    text-align: center;

    > span:first-child {
      margin-bottom: 11px;
    }
  }

  @include tablet {
    max-width: 1024px;
    width: 100%;
    padding: 18px 10px 17px;

    border-top: none;
  }

  @include desktop {
    max-width: 1540px;
    width: 100%;

    border-top: 0;
    padding: 29px 10px 27px;

    &__description {
      text-align: start;

      > :last-child {
        margin-left: 19px;
      }
    }
  }
}
