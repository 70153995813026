@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";

.mobile-gallery {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;

  margin-bottom: 20px;

  &__slide-custom {
    display: grid;
  }

  &__no-image {
    display: block;
    justify-self: center;
    width: 100%;
    max-width: 280px;
    height: 250px;
    max-height: 250px;
    object-fit: contain;

    @include mobile {
      max-width: 400px;
      height: 320px;
      max-height: 320px;
    }

    @include tablet {
      max-width: 600px;
      height: 500px;
      max-height: 500px;
    }
  }

  &__image {
    display: block;
    justify-self: center;
    width: 100%;
    max-width: 280px;
    height: 250px;
    max-height: 250px;
    object-fit: contain;

    @include mobile {
      max-width: 400px;
      height: 320px;
      max-height: 320px;
    }

    @include tablet {
      max-width: 600px;
      height: 500px;
      max-height: 500px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  max-width: calc(100% - 40px);
  width: 100%;
}

.dots {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: calc(100% - 40px - 20px - 104px);
  width: 100%;
}

.dot {
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background-color: $secondary;

  &--active {
    background-color: $primary;
  }

  &--small {
    width: 10px;
    height: 10px;
  }
}

.slider-dots {
  align-self: center !important;
}
