@import "/src/styles/scss/breakpoints";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.products_slider {
  &__list {
    margin: 0 -70px;
    overflow: visible;
  }

  &__track {
    height: 560px;
    margin-top: 100px;

    & > div {
      padding: 0 70px;
    }
  }
}

.about-us {
  &__list {
    max-width: 1200px;
    width: 100%;
  
    margin: 0 -80px;
    overflow: visible;
  }

  &__track {
    height: 390px;
    margin-top: 70px;

    & > div {
      padding: 0 80px;
    }
  }
}

.our-reviews {
  &__list {
    max-width: 1380px;
    width: 100%;
  
    margin: 0 auto;
    overflow: visible;
  }

  &__track {
    height: 350px;
    margin-top: 150px;
  }
}

.banners-slider {
  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;

    text-align: start;

    .slick-active {
      background: $primary;
    }

    & li {
      max-width: 66px;
      width: 100%;
      height: 3px;

      margin: 0 20px 0 0;

      background: $lightGrey;
      
      & button {
        background: transparent;
  
        width: 66px;
        height: 3px;
        
        padding: 0;

        cursor: pointer;

        @include desktop {
          max-width: 108px;
          height: 4px;
        }
      }

      & button::before {
        content: '';

        width: 0;
        height: 0;

        cursor: pointer;
      }


      &:last-child {
        margin: 0;
      }

      @include desktop {
        max-width: 108px;
        height: 4px;
      }
    }

    @include desktop {
      justify-content: flex-start;
      bottom: 9.5%;
      left: 10.5%;

      transform: translateX(0%);
    }
  }
}