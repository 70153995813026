@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 494px;
  width: 100%;
  margin: 0 auto;
  padding: 107px 20px 85px;

  @include desktop {
    padding: 147px 10px 166px;
  }
}

.title {
  @include additionalText;
  color: $textBlack;
  margin-bottom: 40px;

  @include desktop {
    @include bigTitle;
  }
}

.form {
  width: 100%;

  & > *:last-child {
    margin-bottom: 20px;
  }
}

.auth-content {
  display: flex;
  justify-content: center;
  align-items: center;

  @include customizableText(500, 12px, 12px);
  color: $textLightGrey;

  margin-top: 30px;

  &__subtitle {
    position: relative;
    color: $textLightGrey;
    margin-left: 5px;

    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: -5px;
      left: 0;
      background-color: $textLightGrey;
    }
  }

  @include desktop {
    margin-top: 24px;
  }
}

.remember-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  gap: 12px;
  margin-bottom: 30px;

  &__checkbox {
    position: relative;

    min-width: 15px;
    min-height: 15px;

    border: 1px solid $textLighterGrey;
    border-radius: 3px;

    user-select: none;
    cursor: pointer;

    & > img {
      width: 12px;
      height: 12px;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }
  }

  &__title {
    @include customizableText(500, 12px, 12px);
    color: $textLightGrey;
  }

  @include desktop {
    gap: 0;
    margin-top: 4px;
    margin-bottom: 20px;

    &__checkbox {
      margin-right: 10px;
    }
  }
}

.singup-button {
  width: 100%;
  padding: 16px 15px;

  @include desktop {
    margin-top: 8px;
    padding: 12px 15px;
  }
}

.hint-placeholder {
  width: 1px;
  height: 52px;
  margin-bottom: 20px;
}
