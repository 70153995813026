@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 17px;

  -webkit-tap-highlight-color: transparent;
}

.line {
  width: 30px;
  height: 2px;

  background-color: $textBlack;
}