@import "../../../../../../../styles/scss/variables";
@import "../../../../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid $textLighterGrey;

  overflow: hidden;

  width: 100%;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include commonText(500);
  color: $textBlack;

  width: 100%;
  height: 100%;

  padding: 14px 0;

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;

    &_title {
      @include commonText(500);
      color: $textLighterGrey;
    }
  }
}

.plus_icon {
  width: 12px;
  height: 12px;

  transform: rotate(0deg);
  transition: transform 0.2s ease;

  &--rotated {
    transform: rotate(-45deg);
    transition: transform 0.2s ease;
  }
}

.answer {
  width: 100%;

  &__text {
    @include customizableText(400, 13px, 18px);
    color: $textBlack;

    padding-top: 2px;
    padding-bottom: 14px;
  }

  &-enter {
    max-height: 0;
    width: 100%;
  }

  &-enter-active {
    overflow: hidden;
    max-height: 0px;
  }

  &-enter-done {
    height: auto;
    max-height: 150px;
    transition: max-height 0.7s ease;
    overflow: hidden;
  }

  &-exit-active { 
    height: auto;
    max-height: 150px;
  }

  &-exit-done {
    max-height: 0px;
    transition: max-height 0.7s ease;
    overflow: hidden;
  }
}