@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 502px;
  width: 100%;
  margin: 0 auto;
  padding: 107px 20px 85px;

  @include desktop {
    padding: 147px 10px 166px;
  }
}

.title {
  @include additionalText;
  color: $textBlack;
  margin-bottom: 40px;

  @include desktop {
    @include bigTitle;
  }
}

.form {
  width: 100%;
}

.input {
  @include commonText(500);
  height: 52px;

  @include desktop {
    @include additionalText();
  }
}

.code-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 52px;
  margin-bottom: 45px;
  border-radius: 5px;
  background-color: $concrete;

  &__inputs-wrapper {
    max-width: 262px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
  }

  &__input {
    @include additionalText();
    color: $textLightGrey;
    max-width: 46px;
    width: 100%;

    text-align: center;
    margin-right: 5px;
    border-bottom: 2px solid $textLightGrey;
    padding-bottom: 1px;
  }
}

.button {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 30px;
  padding: 16px 15px;

  @include desktop {
    margin-top: 18px;
    padding: 12px 15px;
  }
}

.link {
  @include customizableText(500, 14px, 16px);
  color: $textBlack;

  border-bottom: 1px solid $textBlack;
  padding-bottom: 5px;

  @include desktop {
    @include commonText(500);
  }
}

.links-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.error-message {
  position: absolute;
  transform: translateY(-100%);
  top: -12px;
  color: $primary;

  @include descriptionTextMedium(500);
}

.password {
  padding-right: 70px;
}