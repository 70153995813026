@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  position: relative;

  width: 100%;

  border-bottom: 1px solid $textLightestGrey;
  padding-bottom: 5px;
}

.letters {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding-bottom: 5px;

  @include mobile {
    padding-bottom: 5px;
  }
  @include desktop {
    padding-bottom: 20px;
  }

  overflow-x: auto;

  scrollbar-color: $primary transparent;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    color: $primary;
    border-radius: 20px;
  }

  &__item {
    @include additionalText;
    color: $textBlack;

    position: relative;

    min-width: 40px;
    min-height: 40px;

    @include mobile {
      @include customizableText(500, 18px, 24px);
    }
    @include desktop {
      @include additionalText;
    }

    &--selected {
      border-radius: 50%;
      color: $primary;
      background-color: $secondary;
    }

    &--special {
      margin-left: 5px;
    }
  }
}

.start-shadow {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 10px;
    height: 80%;

    background: rgba(223, 223, 223, 0.79);
    filter: blur(6px);

    @include tablet {
      width: 12px;
      height: 60%;
    }
  }
}

.end-shadow {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0px;
    width: 10px;
    height: 80%;

    background: rgba(223, 223, 223, 0.79);
    filter: blur(6px);

    @include tablet {
      width: 12px;
      height: 60%;
    }
  }
}
