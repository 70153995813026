@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.title {
  @include customizableText(400, 20px, 24px);
  text-align: center;

  @include desktop {
    @include bigTitle;
    color: $textBlack;
  }
}