@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  max-width: 1050px;
  width: 100%;
  min-height: auto !important;
  height: fit-content;

  padding: 10px 20px 0;

  @include tablet {
    align-items: flex-start;

    border-radius: 10px;

    padding: 38px 27px 48px;

    box-shadow: -1px 0px 35px rgba(0, 0, 0, 0.25);
  }

  @include desktop {
    padding: 30px 20px 48px;

    box-shadow: -1px 0px 50px rgba(0, 0, 0, 0.25);
  }
}

.dialog-icon {
  margin-bottom: 20px;

  @include tablet {
    position: absolute;
    top: 20px;
    right: 40px;

    margin-bottom: 0;
  }

  @include desktop {
    top: 30px;
    right: 58px;
  }
}

.username {
  @include commonText(500);
  color: $textBlack;
}

.comment-info {
  @include customizableText(500, 14px, 17px);
  color: $textLightestGrey;
  margin: 9px 0;
}

.description {
  @include customizableText(500, 14px, 17px);
  color: $textLightestGrey;
  margin-bottom: 20px;
  text-align: center;
}

.comment-text {
  width: 100%;
  max-height: 170px;

  @include commonText(400);
  line-height: 149%;
  color: $textBlack;

  text-align: center;
  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  @include tablet {
    text-align: start;
  }
}
