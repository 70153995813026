@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1800px;
  width: 100%;

  margin: 90px auto 0;
  margin-top: 30px;

  @include desktop {
    padding-bottom: 90px;
    margin-top: 90px;
  }
}

.see-more {
  margin-top: 26px;

  @include desktop {
    margin-top: 39px;
  }
}