@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  position: absolute;
  top: 60px;
  left: 0;

  // max-width: 335px;
  width: 100%;

  background-color: $white;
  border-radius: 5px;
  padding: 20px 20px 27px 22px;

  box-shadow: -5px -4px 20px rgba(0, 0, 0, 0.25), 4px 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 35;

  @media (min-width: 450px) {
    max-width: 400px;
  }
}

.cross-icon {
  position: absolute;
  top: 17px;
  right: 20px;

  width: 24px;
  height: 24px;

  color: $primary;
  cursor: pointer;

  @include tablet {
    width: 27px;
    height: 27px;
  }
}

.cities-container {
  max-height: 368px;
}