@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: 15px 15px;

  @include tablet {
    border-radius: 10px;
  }

  @include desktop {
    padding: 20px 20px;
  }

  @media (min-width: 1100px) and (max-width: 1440px) {
    padding: 36px 44px;
  }

  @include largeDesktop {
    padding: 36px 44px;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-bottom: 36px;
  border-bottom: 1px solid $textLighterGrey;
  padding-bottom: 14px;

  &__step {
    @include additionalText();
    color: $primary;
  }
  
  &__text {
    white-space: nowrap;
    @include additionalText();
    color: $textBlack;
  }
}

.warning {
  text-align: center;
  @include additionalText();
  color: $textBlack;
}

