@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.small-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 340px;
  padding: 0 20px;
  width: 100%;

  cursor: pointer;

  @include tablet {
    padding: 0 220px;
  }

  @include desktop {
    height: 462px;
    padding: 0;
    position: relative;
    overflow: visible !important;

    &:hover {
      align-self: center;
      min-height: auto !important;
      height: fit-content;

      .review__additional {
        &_gender-icon {
          position: absolute;
          z-index: 5;
  
          &--male {
            top: -11px;
            left: 0;
          }
      
          &--female {
            top: -21px;
            left: -10px;
          }
        }
      
        &__user-info {
          &_username {
            position: relative;
      
            margin-top: 0;
            z-index: 5;
          }
        
          &_date {
            position: relative;
            @include commonText(500);
            color: $textLighterGrey;
            z-index: 5;
          }
        }
      }
  
      .review-text {
        display: flex;
      
        @include commonText(400);
        color: $textBlack;
  
        max-height: 263px;
        width: 100%;
  
        margin-top: 15px;
  
        overflow-y: auto;
        z-index: 5;
      }
  
      &::after {
        content: '';
        position: absolute;
  
        left: -33px;
        top: -84px;
        right: -33px;
  
        height: calc(100% + 96px);
        background-color: $white;
  
        box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin: 30px 0;
  
        z-index: 4;
      }
    }
  }
}


.big-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 1020px;
  width: 100%;

  margin-bottom: 20px;
  padding: 0 10px;
}


.review__additional {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;

  &_gender-icon {
    z-index: 1;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-left: 0;

    &_username {
      @include commonText(500);
      color: $textBlack;

      margin-top: 13px;
      margin-bottom: 15px;
    }
  
    &_date {
      @include commonText(500);
      color: $textLighterGrey;
    }
  }

  @include tablet {
    flex-direction: row;
    align-items: flex-end;

    width: auto;

    &__user-info {
      display: block;
      margin-left: 30px;

      &_username {  
        margin-top: 15px;
      }
    }
  }

  @include desktop {
    &_gender-icon {
      position: absolute;
  
      &--male {
        top: 17px;
        left: 0;
      }
  
      &--female {
        top: 7px;
        left: -10px;
      }
    }

    &__user-info {
      margin-left: 80px;

      &_username {  
        margin-top: 28px;
      }
    }
  }
}

.review-text {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;

  @include commonText(400);
  color: $textBlack;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  margin-top: 15px;

  @include tablet {
    text-align: start;
  }
}

.likes-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;

  max-width: 230px;
  width: 100%;

  margin-top: 18px;
}

.likes {
  display: flex;
  justify-content: center;
  align-items: center;

  &_icon {
    cursor: pointer;
  }

  &:nth-child(2) {
    & > img {
      transform: rotate(180deg);
    }
  }

  &_amount {
    @include customizableText(500, 16px, 24px);
    margin-top: 6px;
    margin-left: 16px;
  }
}

.big__review-text {
  @include commonText(400);
  color: $textBlack;

  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 30px;
}