@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.notification {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  position: fixed;
  bottom: 80px;
  left: 50%;
  width: 90%;

  border-radius: 5px;
  padding: 28px 52px;

  background-color: $white-smoke;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);

  transform: translateX(-50%);
  z-index: 50;
  cursor: pointer;

  &-enter-active {
    opacity: 0;
  }

  &-enter-done {
    opacity: 1;
    transition: opacity 1.5s ease;
  }

  &-exit-active { 
    opacity: 1;
  }

  &-exit-done {
    opacity: 0;
    transition: opacity 2.0s ease;
  }

  @include desktop {
    bottom: 30px;
  }
}

.icon {
  width: 20px;
  height: 20px;

  margin-right: 9px;

  @include desktop {
    max-width: 400px;
    width: 100%;

    width: 40px;
    height: 40px;
  }
}

.title {
  @include commonText(500);
  color: $textBlack;

  @include desktop {
    @include customizableText(500, 24px, 29px);
  }
}