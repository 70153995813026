@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding-top: 30px;
  padding-left: 10px;
}

.title {
  @include commonText(500);
  color: $textBlack;

  margin-bottom: 20px;
}

.row {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  max-width: 350px;

  &__required {
    position: absolute;
    top: 0;
    right: 0;
    color: $primary;
    @include commonText(600);
  }

  &__input {
    width: 100%;
    @include commonText(500);
    color: $textBlack;

    border-bottom: 1px solid $textLightGrey;
    padding-bottom: 4px;
    padding-right: 10px;

    &::placeholder {
      @include customizableText(500, 14px, 20px);
      color: #888;

      @include desktop {
        @include commonText(500);
      }
    }
  }

  &__error-message {
    position: absolute;
    bottom: -5px;
    left: 0;
    color: $primary;

    @include customizableText(500, 12px, 16px);
    transform: translateY(100%);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  margin-top: 30px;
}
