@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.list-wrapper {
  position: relative;
  min-height: 0;

  width: 100%;
  height: 100%;

  &--top-shadow {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
  
      width: 100%;
      height: 20px;
  
      background-color: $gainsboro;
      filter: blur(15px);
      z-index: 10;
    }
  }
  &--bottom-shadow {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
  
      width: 100%;
      height: 20px;
  
      background-color: $gainsboro;
      filter: blur(15px);
      z-index: 10;
    }
  }
}

.list {
  width: 100%;
  height: 100%;

  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.search-input {
  max-width: calc(100% - 40px);
}

.error {
  @include commonText(500);
  color: $textBlack;

  text-align: center;
  align-self: center;
  padding: 10px 0 12px;
}

.accept-filters {
  max-width: 231px;
  width: 100%;

  align-self: center;
  margin-top: 20px;
}