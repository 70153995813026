@import "../../styles/scss/variables";

.root {
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.scrollbar {
  display: none;
  place-items: center;
  width: 10px;
  padding-left: 4px;

  &--show {
    display: block;
  }

  &__content {
    display: block;
    position: relative;
    top: 0;
    right: 0px;

    height: 100%;
    width: 4px;

    &_track {
      position: absolute;
      top: 0;
      bottom: 0;

      width: 4px;
      height: 100%;

      background-color: $lightSilver;

      cursor: pointer;
    }

    &_thumb {
      position: absolute;

      width: 4px;

      background-color: $textBlack;
    }
  }
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .scrollbar__content_thumb {
      height: auto !important;
    }
  }
}

.scrollbar__content {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $lightSilver;
  }

  &::-webkit-scrollbar-thumb {
    background: $textBlack;
  }
}
