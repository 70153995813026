@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $white;
  padding: 30px 15px;

  @include tablet {
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px 15px;
  }

  @include desktop {
    padding: 36px 44px;
  }
}

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;

  margin-bottom: 64px;
  border-bottom: 1px solid $textLighterGrey;
  padding-bottom: 14px;
}

.step {
  position: absolute;
  top: 50%;
  left: 0;

  @include additionalText();
  color: $primary;

  transform: translateY(calc(-50% - 7px));
}

.title {
  @include additionalText();
  color: $textBlack;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0;
  
  max-width: 500px;
}

.city {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  width: 100%;

  margin-bottom: 10px;
  border-bottom: 1px solid $textLighterGrey;
  padding-bottom: 6px;

  &__title {
    @include customizableText(500, 18px, 22px);
    color: $textBlack;
  }

  &__button {
    @include commonText(500);
    color: $textLighterGrey;

    text-decoration: underline;
  }

  &__modal {
    top: 35px;
  }

  &__cities-container {
    max-height: 368px;
  }
}

.address {
  display: flex;
  cursor: pointer;

  @include commonText(500);
  color: $textBlack;

  align-self: flex-start;
  margin-top: 30px;
  margin-bottom: 38px;

  :last-child {
    margin-left: 5px;
    color: $primary;
  }
}

.select-address {
  @include commonText(500);
  color: $textBlack;

  border-bottom: 1px solid $textBlack;
  padding-bottom: 6px;
}

.map {
  width: 100%;
  height: calc(100vh - 150px);

  flex-grow: 1;
}

.bottom-sheet {
  height: 100dvh;
}
