@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
  overflow: hidden;
}

.brands-container {
  position: relative;
  width: 100%;
  height: calc(100% - 101px);

  padding-top: 60px;

  @include mobile{
    padding-top: 30px;
  }
  @include tablet{
    padding-top: 45px;
  }
  @include desktop{
    padding-top: 50px;
  }
  @include largeDesktop{
    padding-top: 60px;
  }


  &--top-shadow {
    &::before {
      content: "";
      position: absolute;
      top: 44px;
      left: 5px;

      width: calc(100% - 20px);
      height: 12px;

      @include mobile{
        top: 15px;
        height: 5px;
      }
      @include tablet{
        top: 30px;
      }
      @include desktop{
        top: 34px;
        height: 12px;     
      }
      @include largeDesktop{
        top: 44px;
        height: 12px;     
      }

      background: rgba(223, 223, 223, 0.79);
      filter: blur(9px);
    }
  }

  &--bottom-shadow {
    &::after {
      content: "";
      position: absolute;
      bottom: -14px;
      left: 5px;

      width: calc(100% - 20px);
      height: 12px;
      
      background: rgba(223, 223, 223, 0.79);
      filter: blur(9px);

      @include mobile{
        height: 5px;
      }
      @include desktop{
        height: 12px;
      }
    }
  }
}

.brands {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;

  width: 100%;
  height: 100%;

  gap: 20px 10px;

  @include mobile{
    grid-template-columns: repeat(2, 1fr);
  }
  @include tablet{
    grid-template-columns: repeat(3, 1fr);
  }

  overflow-y: auto;
  scrollbar-color: $primary transparent;
  -webkit-transform: translate3d(0, 0, 0);

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    color: $primary;
    border-radius: 20px;
  }

  &__item {
    @include additionalText;
    color: $textBlack;

    @media (max-width: 1200px) {
      @include customizableText(500, 18px, 24px);

      @media(max-width: 620px){
        @include customizableText(500, 16px, 24px)
      }
    }
    @include largeDesktop{
      @include additionalText
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
