@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 177px;
    margin-bottom: 73px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    padding: 0 100px;
    margin-bottom: 108px;
  }
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}