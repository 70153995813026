@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include commonText(500);

  position: absolute;
  top: 25px;
  right: 0;

  width: 100%;

  border-radius: 5px;
  padding: 15px 11px 12px;

  background-color: $white;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
  z-index: 6;

  @include desktop {
    width: 227px;
  }
}

.button {
  color: $textBlack;
  margin-bottom: 9px;
  cursor: pointer;

  &--selected {
    color: $primary;
  }
}
