.like-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;

  &__heart {
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    transform: scale(2.5);
  }

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
    cursor: pointer;
  }
}
