@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  width: 100%;

  @include desktop {
    width: auto;
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px 0;

  width: 100%;

  &__item {
    list-style-type: none;
    padding-bottom: 4px;

    a, button {
      @include commonText(500);
      color: $textBlack;
    }

    &--active {  
      box-shadow: inset 0px -2px $primary;
      transition: box-shadow 0.3s ease-in-out;
    }
  }

  @media (min-width: 451px) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 0 20px;
    overflow-x: auto;
    white-space: nowrap;
  }

  @media (min-width: 700px) {
    //in case if content of the list exceeds the auto scroll size, change justify to flex-start
    justify-content: center;
  }

  @include tablet {
    flex-direction: row;
    gap: 0 35px;
  } 

  @include desktop {
    justify-content: space-between;
    align-items: center;

    width: auto;
    gap: 0 45px;

    &__item {
      padding-bottom: 8px;
      a, button {
        @include additionalText();
      }
    }
  }

  @media (min-width: 1100px) {
    gap: 0 55px;
  }
}