@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.dropdown {
  margin-bottom: 50px;

  @include tablet {
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 80px;
  }

  @media (min-width: 811px) and (max-width: 1023px) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  @include desktop {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }
}

.dropdown-content {
  @include tablet {
    grid-template-rows: 1fr;
  }

  @media (min-width: 811px) and (max-width: 1023px) {
    grid-template-rows: 1fr;
  }

  @include desktop {
    grid-template-rows: 1fr;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  @include commonText(500);
  color: $textBlack;

  @include tablet {
    display: none;
  }

  &--disabled {
    @include commonText(500);
    color: $textBlack;
    display: none;

    @include tablet {
      display: flex;
    }

    @media (min-width: 811px) and (max-width: 1023px) {
      margin-bottom: 20px;
    }

    @include desktop {
      margin-bottom: 20px;
    }
  }
}

.catalog-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  &__title {
    margin-right: 7px;
  }

  &__symbol {
    width: 10px;
    height: 20px;

    @include tablet {
      display: none;
    }

  }

  &__item {
    @include commonText(400);
    color: $textBlack;
    margin-bottom: 10px;
  }

  @include tablet {
    &__item {
      @include customizableText(400, 14px, 17px);
    }
  }

  @media (min-width: 811px) and (max-width: 1023px) {
    margin-top: 0;
  }


  @include desktop {
    margin-top: 0;
  }
}
