@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include commonText(400);
  color: $textBlack;

  width: 100%;

  border-radius: 10px;
  border: 1px solid $textLighterGrey;
  padding: 17px 23px;

  @include desktop {
    margin-top: 29px;
    border-radius: 0;
    border: 0;
    padding: 0 24px;
  }
}

.title {
  margin-bottom: 15px;

  @include desktop {
    margin-bottom: 25px;
  }
}