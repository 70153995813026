@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/variables";

.product-gallery {
  display: none;
  margin-bottom: 30px;

  @include desktop {
    display: grid;
    width: 100%;
    height: fit-content;
    max-width: 480px;
    max-height: 400px;
    gap: 15px;
    grid-template-columns: 156px minmax(300px, 480px);
    grid-template-rows: 400px;
  }

  @media (min-width: 1110px) {
    max-width: 839px;
    max-height: 658px;
    grid-template-columns: 156px minmax(400px, 668px);
    grid-template-rows: minmax(400px, 658px);
  }

  &--empty {
    display: block;
    margin-bottom: 40px;
  }

  &__no-image {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__outer-images-wrapper {
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 156px;
    height: 400px;
    max-height: 400px;

    @media (min-width: 1110px) {
      height: 658px;
      max-height: 658px;
    }
  }

  &__sub-images-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    max-width: 156px;
    max-height: 400px;
    overflow-y: auto;
    scrollbar-width: none;

    @media (min-width: 1110px) {
      max-height: 658px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__sub-image-item {
    display: flex;
    justify-content: center;
    align-items: baseline;
    min-width: 156px;
    max-width: 156px;
    width: 156px;
    min-height: 120px;
    max-height: 120px;
    height: 120px;
    border: 2px solid transparent;
    border-radius: 3px;
    transition: border 300ms ease;

    &:hover {
      border: 2px solid rgba(199, 60, 135, 0.2);
    }

    &--active {
      border: 2px solid $primary;

      &:hover {
        border: 2px solid $primary;
      }
    }
  }

  &__sub-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__main-image-wrapper {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    width: 100%;
    max-height: 400px;
    height: 100%;

    @media (min-width: 1100px) {
      max-width: 668px;
      max-height: 601px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__main-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.scroll-button {
  position: absolute;
  display: flex;
  content: "";
  width: 100%;
  height: 20px;
  min-height: 20px;
  cursor: pointer;
  z-index: 100;
  transition: opacity 300ms ease;

  &--start {
    top: -30px;
    & > span {
      position: absolute;
      content: "";
      top: 8px;
      width: 50%;
      height: 3px;
      background-color: $primary;
      border-radius: 5px;

      &:first-child {
        left: 2px;
        transform: rotate(-13deg);
        border-bottom-right-radius: 0px;
      }

      &:last-child {
        right: 2px;
        transform: rotate(13deg);
        border-bottom-left-radius: 0px;
      }
    }
  }

  &--end {
    bottom: -30px;
    & > span {
      position: absolute;
      content: "";
      bottom: 8px;
      width: 50%;
      height: 3px;
      background-color: $primary;
      border-radius: 5px;

      &:first-child {
        left: 2px;
        transform: rotate(13deg);
        border-bottom-right-radius: 0px;
      }

      &:last-child {
        right: 2px;
        transform: rotate(-13deg);
        border-bottom-left-radius: 0px;
      }
    }
  }

  &:hover {
    opacity: 0.7;
  }
}
