@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.product-price {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  &__total-price{
    @include commonText(500);
    color: $textLighterGrey;
    text-decoration: line-through;
    white-space: nowrap;
  }

  &__sale-price {
    @include commonText(500);
    color: $primary;
    white-space: nowrap;
  }
}
