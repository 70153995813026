@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 285px;
  width: 100%;

  border-radius: 10px;
  padding: 36px 0;
}

.title {
  @include commonText(500);
  color: $textBlack;

  margin-bottom: 25px;
}

.reset-filters {
  @include commonText(500);
  color: $primary;

  margin-bottom: 15px;
  border-color: $primary;
  padding: 8px 29px;
}

.accept-filters {
  padding: 11px 65.5px;
}