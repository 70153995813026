@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon {
  width: 100%;
  padding: 5px;
}

.phone-icon {
  display: block;
  width: 25px;
  height: 27px;
}

.contacts {
  position: absolute;
  top: 50px;
  right: 100%;
  transform: translateX(50%);

  width: 285px;

  opacity: 0;
  background-color: $athensGray;
  box-shadow: 0px 1px 20px 6px rgba(0, 0, 0, 0.25);

  &-enter-done {
    opacity: 1;
    transition: all 150ms ease;
  }

  @include desktop {
    right: 50%;
  }
}
