@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";


.tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    @include commonText(500);
    color: $darkgrey;
  
    max-width: 564px;
    width: 100%;
  
    margin: 35px auto 0px;
    gap: 15px;
  
    overflow-x: auto;
  
    scrollbar-width: none;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
      width: 0;
    }
  
    &_button {
      padding: 9px 10px;
      white-space: nowrap;
  
      &--active {
        box-shadow: 0 0 0 2px $darkgrey;
        border-radius: 10px;
        transition: box-shadow 0.1s ease-in-out;
      }
  
      &:first-child {
        margin-left: 20px;
      }
  
      &:last-child {
        margin-right: 20px;
      }
    }
  
    &:after {
      content: "";
      display: block;
      height: 42px;
      width: 100%;
    }
  
    @include tablet {
      margin: 50px auto 0;
    }
  
    @include desktop {
      max-width: 564px;
      
  
      gap: 0;
      overflow: visible;
    }
  }