@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.primary {
  @include customizableText(500, 15px, 18px);

  border-bottom: 1px solid $textLightGrey;
  color: $textLightGrey;

  outline: none;
  -webkit-user-select: none;
  user-select: none;

  cursor: pointer;
}

.secondary {
  @include commonText(500);

  border-radius: 5px;
  padding: 12px 25px;

  color: $white;
  background-color: $primary;

  outline: none;
  -webkit-user-select: none;
  user-select: none;

  cursor: pointer;
}

.white {
  color: $textBlack;
  border: 1px solid $textBlack;
  background: $white;
}

.black {
  color: $white;
  border: 1px solid $textBlack;
  background: $textBlack;
}