$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
}

.line {
	width: 140px;
	height: 16px;
	margin-top: 12px;
	border-radius: 7px;

	background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
	background-size: 600px;
	animation: shine-lines 3.6s infinite linear;
}

@keyframes shine-lines {
	0% {
		background-position: -100px;
	}

	40%,
	100% {
		background-position: 140px;
	}
}
