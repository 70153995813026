@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 502px;
  width: 100%;

  margin: 0 auto;
  padding: 107px 20px 85px;
  gap: 20px 0;

  @include desktop {
    padding: 147px 10px 166px;
  }
}

.title {
  @include additionalText;
  color: $textBlack;
  margin-bottom: 20px;

  @include desktop {
    @include bigTitle;
  }
}

.link-primary {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 52px;
  text-align: center;

  @include desktop {
    text-align: start;
    white-space: nowrap;
  }
}

.links-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.link-secondary {
  @include customizableText(500, 14px, 16px);
  color: $textBlack;

  border-bottom: 1px solid $textBlack;
  padding-bottom: 5px;

  @include desktop {
    @include commonText(500);
  }
}