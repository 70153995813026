@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  width: 100%;

  background-color: $white;

  margin: 85px 0;

  @include tablet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 100px 0;
    padding: 40px 0 40px;
  }

  @include desktop {
    margin: 90px 0;
    padding: 40px 0 58px;
  }
}

.main-title {
  margin-bottom: 40px;

  @include desktop {
    margin-bottom: 0;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 10px 10px;

  justify-content: space-between;
  max-width: 900px;
  width: 100%;
  padding: 0 20px;

  @include tablet {
    gap: 30px 30px;
  }

  @include desktop {
    grid-template-columns: repeat(3, minmax(80px, 400px));
    gap: 50px 50px;
    
    max-width: 1300px;
  
    padding-top: 98px;
  }
}

.dots {
  & > button {
    width: 10px;
    height: 10px;
  }
}