@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232px;
  height: 64px;
  padding: 20px;
  border-radius: 16px;
  background: linear-gradient(0deg, #191b38 0%, #474c9e 100%);
  color: $white;
  text-decoration: none;
  @include customizableText(500, 20px, 24px);

  .icon {
    margin-left: 8px;
    width: 12px;
    height: 12px;
  }
}
