@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  min-height: 100dvh;
}

.button-wrapper {
  overflow-y: scroll;
  display: flex;

  &-button {
    @include customizableText(500, 12px, 12px);
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 6px 14px;
  }
}

.search-wrapper {
  &_letter {
    margin: 20px;
    @include customizableText(500, 40px, 44px);
    color: $primary;
  }
}

.brands-wrapper {
  padding-left: 40px;
  overflow-y: scroll;
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-auto-rows: min-content;
  gap: 20px 20px;
  height: calc(100dvh - 60px - 44px - 46px - 33px - 90px);

  &_link {
    @include customizableText(500, 12px, 14px);
    color: $textBlack;
  }
}

.letter-wrapper {
  overflow: hidden;
  &_root {
    border-bottom: none;
    padding-bottom: 5px;

    &-item {
      @include customizableText(500, 12px, 16px);
    }
  }
}
