.arrow {
  stroke: #c73c87;
  fill: #c73c87;

  width: 52px;
  height: 52px;

  z-index: 50;

  &--right {
    transform: rotate(0deg)
  }

  &--left {
    transform: rotate(180deg)
  }
}