@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 40px;
}

.title {
  @include commonText(500);
  color: $textLightGrey;
  text-align: center;

  margin-bottom: 44px;

  @include desktop {
    text-align: start;
  }
}

.social-media_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 40px 0;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;

    gap: 0;
  }
}

.social-media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon-wrapper {
    position: relative;
    width: 64px;
    height: 64px;

    background-color: $white-smoke;
    border-radius: 100%;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 36px;
    height: 36px;
  }

  &__title {
    @include commonText(500);
    color: $textLightGrey;
    margin-top: 14px;
  }
}