@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  // width: 100%;
  padding-bottom: 20px;
  justify-items: center;
  border-bottom: 1px solid $textLighterGrey;

  @include desktop {
    grid-template-columns: 100px 2fr 1fr;
  }
}

.image {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  grid-row: 1 / span 2;

  @include desktop {
    grid-row: 1;
  }
}

.product-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 400px;

    @include commonText(500);
    color: $primary;
    margin-bottom: 17px;
    overflow: hidden;
    text-overflow: ellipsis;

    @include desktop {
      text-align: center;
    }
  }

  &__code {
    @include customizableText(500, 12px, 14px);
    color: $textLightGrey;
  }
}

.price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  max-width: 400px;

  @include desktop {
    gap: 10px;
    padding: 0px;
  }
}

.main-price {
  @include commonText(500);
  color: $textLighterGrey;
  text-decoration: line-through;
  white-space: nowrap;
}

.sale-price {
  @include commonText(500);
  color: $primary;
  white-space: nowrap;
  margin-left: 5px;
}

.amount {
  @include commonText(500);
  color: $textBlack;
  white-space: nowrap;
  margin-left: 5px;
}
