@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);

  @include tablet {
    width: calc(100% - 80px);
  }

  @include largeDesktop {
    width: calc(100% - 20px);
    flex-direction: row;
    align-items: center;
    gap: 30px;

    &.left {
      flex-direction: row;
    }

    &.right {
      flex-direction: row-reverse;
    }
  }

  @media (min-width: 1520px) {
    max-width: 1520px;
  }
}

.content {
  width: 100%;

  @include largeDesktop {
    width: 65%;
  }
}

.title {
  @include customizableText(800, 32px, auto);
  text-align: left;
  margin-bottom: 30px;
  margin-top: 30px;

  @include tablet {
    @include customizableText(800, 40px, auto);
  }
}

.tabs {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 30px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tab {
  padding: 10px 20px;
  border-radius: 100px;
  background-color: rgba(25, 27, 56, 0.1);
  color: $darkBlue;
  white-space: nowrap;
  margin-right: 8px;
  cursor: pointer;
  @include customizableText(600, 20px, auto);

  &.active {
    background-color: $darkBlue;
    color: $white;
  }
}

.products {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 700px) and (max-width: 809px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;

    & > * {
      flex: 1 1 calc(50% - 16px);
    }
  }

  @include tablet {
    flex-direction: row;
    gap: 16px;
  }

  @include desktop {
    gap: 8px;
    justify-content: flex-start;
  }

  @media (min-width: 1060px) {
    gap: 16px;
  }

  @include largeDesktop {
    gap: 16px;
    flex-wrap: nowrap;
  }

}

.view {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @include tablet {
    margin-bottom: 11px;
  }
}