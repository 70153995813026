@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  max-width: 559px;
  width: 100%;

  border-bottom: 1px solid $textLighterGrey;
  padding: 12px 0;

  cursor: pointer;
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 20px;
  min-height: 20px;

  margin-right: 9px;
  border: 2px solid $textLighterGrey;
  border-radius: 50%;

  &-inner {
    width: 10px;
    height: 10px;

    background-color: $primary;

    border-radius: 50%;
  }
}

.offer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 366px;
  width: 100%;

  color: $textBlack;

  margin-right: 11px;

  &_volume {
    @include customizableText(500, 12px, 15px);
    white-space: nowrap;
  }

  &_separator {
    margin-right: 5px;
    padding: 0 5px;
  }

  &_description-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    max-width: 300px;
    width: 100%;
  }

  &_description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @include desktop {
      -webkit-line-clamp: 2;
    }

    max-width: 294px;
    width: 100%;

    @include customizableText(500, 12px, 12px);

    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.promotion-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include customizableText(500, 12px, 15px);

  @include mobile {
    justify-content: flex-start;
    flex-direction: row;
  }

  @include desktop {
    width: 100%;
  }

  max-width: 176px;
  width: 80%;

  &__icon-percent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__percent {
    color: $primary;
    margin-right: 5px;
  }

  &__markdown {
    min-width: 20px;
    display: flex;
    align-items: center;

    @include mobile {
      min-width: 30px;
    }

    & img {
      width: 16px;

      @include mobile {
        width: 20px;
      }
    }
  }

  &__price {
    display: inline-block;
    margin-right: 0;
    width: auto;
    text-align: center;
    white-space: nowrap;

    @include mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 3px;
      width: 100%;
    }

    &--through {
      display: flex;
      color: $textLightGrey;
      text-decoration: line-through;
      white-space: nowrap;
    }
  }
}
