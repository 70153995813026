@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  max-width: 1537px;
  width: 100%;

  margin: 57px auto 0;

  padding: 30px 10px;

  @include desktop {
    padding: 30px 50px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  padding-bottom: 65px;

  @include desktop {
    padding-bottom: 36px;
  }
}

.city-select {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 250px;
  width: 100%;

  &_icon {
    width: 28px;
    height: 32px;

    align-self: center;

    margin-right: 15px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include additionalText();
    color: $grey;

    &_button {
      background-color: inherit;

      padding-bottom: 2px;
      border-bottom: 2px solid $grey;

      outline: none;
      cursor: pointer;

      &--selected {
        color: $textBlack;
        border-bottom: 2px solid $primary;
      }
    }
  }
}

.logo {
  max-width: 335px;
  width: 100%;
  max-height: 80px;
  height: 100%;
}

.logo-tablet {
  max-width: 245px;
  width: 100%;
  max-height: 46px;
  height: 100%;
}

.logo-wrapper-tablet {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.cities-suggestion-modal {
  position: absolute;
  top: 60px;
  left: 0;

  width: 371px;
  height: 140px;

  border-radius: 10px;
  padding: 26px 22px;

  box-shadow:
    -5px -4px 20px rgba(0, 0, 0, 0.25),
    4px 4px 20px rgba(0, 0, 0, 0.25);

  z-index: 10;

  &--shadow-disabled {
    box-shadow: none;
  }
}

.select-city-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  position: absolute;
  top: 60px;
  left: 0;

  max-width: 535px;
  width: 100%;

  background-color: $white;
  border-radius: 5px;
  padding: 20px 38px 27px 22px;

  box-shadow:
    -5px -4px 20px rgba(0, 0, 0, 0.25),
    4px 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 15;
}
