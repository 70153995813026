@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.title {
  @include customizableText(600, 16px, 20px);
  color: $lighterBlack;
  margin-right: 30px;
}

.search-error {
  @include commonText(500);
  color: $textBlack;

  width: 100%;

  margin-top: 32px;
  text-align: center;
}

.input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;
  margin-top: 37px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 14px;

  width: 18px;
  height: 18px;

  transform: translateY(-50%);
}

.textarea {
  @include customizableText(500, 14px, 15px);
  color: $textBlack;
  width: 100%;
  border-radius: 20px;
  border: 2px solid $textBlack;
  padding: 9px 20px 9px 55px;

  &::placeholder {
    color: $textLighterGrey;
  }

  @media (min-width: 280px) and (max-width: 320px) {
    height: 73px !important;
  }

  @media (min-width: 321px) and (max-width: 349px) {
    height: 63px !important;
  }

  @media (min-width: 350px) and (max-width: 369px) {
    height: 60px !important;
  }

  @media (min-width: 370px) and (max-width: 420px) {
    height: 53px !important;
  }
}

.description {
  @include customizableText(500, 12px, 15px);
  color: $textLighterGrey;

  margin-top: 10px;
  margin-bottom: 32px;
}

.cities-wrapper {
  position: relative;
  min-height: 0;
  overflow: hidden;
  width: 100%;

  &--top-shadow {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 20px;

      background-color: $gainsboro;
      filter: blur(15px);
      z-index: 10;
    }
  }
  &--bottom-shadow {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 20px;

      background-color: $gainsboro;
      filter: blur(15px);
      z-index: 10;
    }
  }
}

.cities {
  overflow: auto;
  scrollbar-width: thin;
  display: grid;
  width: 100%;
  height: 100%;
  gap: 9px;

  &::-webkit-scrollbar {
    width: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    @include commonText(500);
    color: $textBlack;
    text-align: start;

    &--region {
      @include descriptionTextMedium(400);
      color: $lightGrey;
    }
  }
}

.loading {
  height: 368px;

  &__title {
    @include additionalText;
  }
}
