@import "../../../../styles/scss/variables";
@import "../../../../styles/scss/mixins/typography";

.root {
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  overflow: hidden;
  z-index: 99;
}

.background {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);

  z-index: -1;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 500px;
  width: 100%;

  border-radius: 10px;
  padding: 32px;

  background-color: $white;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);

  z-index: 100;
}


.title {
  @include additionalText();
  color: $textBlack;

  margin-top: 30px;
  text-align: center;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 30px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 205px;
  width: 100%;
  height: 52px;
}