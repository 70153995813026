@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  border-radius: 5px;
  padding: 12px 30px 17px;
}

.main-title {
  @include commonText(500);
  color: $primary;
}

.subtitle {
  @include customizableText(500, 14px, 17px);
  color: $textBlack;

  &:is(p) {
    padding: 6px 0 26px;
  }
}

.phones-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin: 13px 0 30px;
  gap: 5px 0;
}

.links-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 23px;
  gap: 0 25px;

  & > a {
    filter: invert(40%) sepia(3%) saturate(9%) hue-rotate(74deg) brightness(95%)
      contrast(96%);
  }
}
