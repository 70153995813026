@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  position: relative;
  height: fit-content;

  @include desktop {
    margin-right: 120px;
  }
}

.button {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 2;

  justify-items: flex-end;
  align-items: center;

  position: relative;
  height: fit-content;

  @include desktop {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: auto;
    justify-items: center;
  }

  &__button-wrapp {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.title {
  align-self: flex-start;
  color: $tintGrey;

  @include customizableText(500, 14px, 17px);

  @include desktop {
    @include commonText(500);
    margin-right: 30px;
  }
}

.sort-title {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  grid-row: 2;

  @include customizableText(500, 14px, 17px);
  color: $textBlack;
  text-align: end;

  @include desktop {
    width: auto;

    grid-row: 1;
    grid-column: 2;

    @include commonText(500);

    margin-right: 9px;
  }
}

.arrow {
  width: 6px;
  height: 12px;
  transform: rotate(90deg);
  transition: transform 0.2s linear;
  margin-left: 5px;

  &--rotated {
    transform: rotate(270deg);
    transition: transform 0.2s linear;
  }
}

.inner-content {
  @include mobile {
    white-space: nowrap;
    width: auto;
    top: 40px;
    @include customizableText(500, 12px, 20px);
  }
  @include desktop {
    top: 25px;
    @include commonText(500);
  }
}

.bottomsheet__content {
  position: relative;
  top: 0;
  left: 0;

  padding-bottom: 0;
  box-shadow: none;
}
