@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user-info {
  max-width: 950px;
  width: 100%; 
}

.adress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 950px;
  width: 100%;

  padding: 40px 20px 0;

  @include desktop {
    padding: 0;
  }
}