@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.card {
  width: 100%;
  max-width: 320px;
  height: 425px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @include tablet {
    max-width: 230px;
  }

}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 320px;
  height: 230px;
  border: 1px solid $gainsboro;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 20px;

  @include tablet {
    max-width: 230px;
  }

  img {
    max-width: 188px;
    max-height: 188px;
    object-fit: contain;
  }
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__price {
    width: 100%;
    max-width: 320px;
    height: 29px;
    @include customizableText(700, 24px, 29.26px);
    text-align: left;
    color: $textBlack;
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet {
      max-width: 230px;
    }
  }

  &__sale {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__percent {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      gap: 10px;
      border-radius: 70px;
      background: #ff0044;
      opacity: 1;
      @include customizableText(600, 18px, 21.94px);
      text-align: left;
      color: $white;
    }
  }

  &__old-price {
    @include customizableText(600, 18px, 21.94px);
    text-align: left;
    color: #a6a6a6;
    text-decoration: line-through;
    margin-left: 10px;
  }

  &__name {
    width: 100%;
    max-width: 320px;
    @include customizableText(500, 20px, 24.38px);
    text-align: left;
    color: $textBlack;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 6px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-right: 10px;
    height: 48px;

    @include tablet {
      max-width: 230px;
      -webkit-line-clamp: 3;
      height: 72px;
    }
  }

  &__volume {
    width: 230px;
    @include customizableText(600, 18px, 21.94px);
    text-align: left;
    color: #a6a6a6;
  }

  &__separator{
    display: inline-block;
    text-align: center;
    width: 26px;
    color: $lighterGrey;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    margin: 0 5px;

    @include tablet {
      width: 20px;
    }
  }

  &__separator::before {
    content: "";
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 3px;
    background-color: $lighterGrey;
    vertical-align: middle;

    @include tablet {
      width: 18px;
    }
  }
}
