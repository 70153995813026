@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  position: relative;

  max-width: 180px;
  width: 100%;

  @include commonText(500);
  color: $textBlack;

  margin: 8px 0;
}

.input {
  width: 100%;
  border: 1px solid $textLighterGrey;
  border-radius: 5px;
  padding: 8px 10px 8px 35px;
}

.search-icon {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 20px;
  height: 20px;
}

.cross-button_wrapper {
  position: absolute;
  right: 5px;

  border-radius: 8px;
  padding: 5px 8px;

  &:hover {
    background-color: $gainsboro;
  }
}