@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.dropdown {
  border-top: 1px solid $textLighterGrey;
  border-bottom: 1px solid $textLighterGrey;

  @include desktop {
    border-top: 0;
  }
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include commonText(500);
  color: $textBlack;

  width: 100%;
  height: 100%;

  padding: 14px 0;

  &__title {
    @include commonText(500);
    color: $textLighterGrey;
    margin-left: 19px;
  }
}

.button-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus_icon {
  width: 12px;
  height: 12px;

  transform: rotate(0deg);
  transition: transform 0.2s ease;

  &--rotated {
    transform: rotate(-45deg);
    transition: transform 0.2s ease;
  }
}

.description {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;

  &-wrapper {
    min-height: 0;
  }

  &__text {
    @include customizableText(500, 12px, 17px);
    color: $textBlack;

    text-align: center;

    padding-top: 6px;
    padding-bottom: 12px;

    @include desktop {
      text-align: start;

      padding-bottom: 15px;
    }
  }

  &-enter {
    grid-template-rows: 0fr;
  }

  &-enter-done {
    grid-template-rows: 1fr;
    transition: grid-template-rows 0.4s ease;
  }

  &-exit { 
    grid-template-rows: 1fr;
  }

  &-exit-done {
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.4s ease;
  }
}
