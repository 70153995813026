@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 95px;
  background-color: transparent;

  margin-bottom: 14px;
  border-radius: 10px;
  border: 1px solid $textLighterGrey;

  &--selected {
    border: 0;
    box-shadow: 0px 0px 11px 2px rgba(100, 100, 100, 0.48);
  }
}

.default-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dolyami-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  gap: 20px;
  border-radius: 10px;
  
  background-color: transparent;
  border: 1px solid $textLighterGrey;

  padding: 10px;

  @media (min-width: 600px) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 95px;

    padding: 10px 20px;
  }
}

.title {
  @include commonText(500);
  color: $textBlack;
}

.price {
  @include commonText(500);
  color: $textBlack;
  opacity: 0.4;

  border-radius: 30px;
  border: 1px solid $textBlack;
  padding: 7px 22px;
}

.limit {
  @include customizableText(700, 12px, 15px);
  color: $textLighterGrey;
}