@import "../../../../../../../styles/scss/variables";
@import "../../../../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  max-width: 350px;
  width: 100%;

  padding-top: 28px;
}