@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.description {
  @include customizableText(500, 16px, 24px);
  color: $textBlack;

  text-align: center;
  padding: 35px 0;

  @include desktop {
    text-align: start;
    padding: 25px 0 40px;

    &-empty {
      margin: 40px 0;
      width: 100%;
      text-align: center;
      @include customizableText(500, 18px, 28px);
    }
  }
}
