.cart-product-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  &__quantity-and-delete-wrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 30px;
  }
}
