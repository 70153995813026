@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.root {
  position: relative;
  padding-bottom: 50px;
}

.text {
  overflow: hidden;

  &::after {
    content: "";
    height: 60%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.212), $white);
  }

  &-short {
    max-height: 150px;
    text-overflow: ellipsis;
    transition: max-height 0.5s ease;

    &::after {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
  }

  &-showed {
    max-height: 700px;
    transition: max-height 1s ease;

    &::after {
      opacity: 0;
      transition: opacity 1s ease;
    }
  }
}

.show-more {
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
}

.button {
  position: relative;
  color: $primary;

  &::after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0;
    display: block;
    width: 0;
    height: 1px;
    background: $primary;
    transform: translateX(-50%);

    transition: 0.3s;
  }

  &:hover::after {
    width: 100%;
  }
}
