@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  @include customizableText(600, 14px, 17px);
  color: $textBlack;

  margin-left: 6px;
  white-space: nowrap;
}

.bottomsheet {
  &__header-line {
    justify-content: flex-start;
    position: relative;

    width: 100%;

    margin-bottom: 35px;
    border-bottom: 1px solid $textBlack;
    padding-bottom: 6px;

    & > button {
      filter: brightness(0);
    }

    & > span {
      position: absolute;
      top: 50%;
      left: 50%;

      @include customizableText(500, 14px, 17px);
      color: $textBlack;

      transform: translate(-50%, -50%);
    }
  }

  &__header-line-shadow {
    &::before {
      content: "";
      position: absolute;
      bottom: -45px;
      left: -4px;
  
      width: calc(100% + 8px);
      height: 20px;
  
      background: rgba(223, 223, 223, 0.79);
      filter: blur(9px);
    }
  }
}

.content {
  min-height: 0;
  flex-grow: 1;
  position: relative;

  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__filters {
    max-width: 100%;

    border: 0;
    padding: 25px 0 0;
  }

  @include desktop {
    &__filters {
      padding: 25px 0;
    }
  }
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;

  &--bottom-shadow {
    &::after {
      content: "";
      position: absolute;
      top: -15px;
      left: -4px;
  
      width: calc(100% + 8px);
      height: 20px;
  
      background: rgba(223, 223, 223, 0.79);
      filter: blur(9px);
    }
  }
}

.accept-filters {
  max-width: 231px;
  width: 100%;

  margin-top: 30px;
  margin-bottom: 17px;
}

.reset-button {
  align-self: center;

  @include customizableText(500, 13px, 16px);
  color: $sonicSilver;

  width: max-content;

  border-bottom: 1px solid $sonicSilver;
  padding: 4px;
}