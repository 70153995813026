@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  position: relative;

  @include commonText(500);
  color: $textBlack;
  
  width: 100%;

  list-style: none;
  margin-bottom: 14px;
  border-radius: 10px;
  border: 1px solid $textLighterGrey;

  @include desktop {
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
  }
}

.main-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: relative;

  padding: 10px 24px;

  width: 100%;
  @include desktop {
    justify-content: center;
    padding: 14px 0;
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}