@import "/src/styles/scss/breakpoints";

.root {
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
  
	@include desktop {
	  align-items: flex-start;
	}
  }
  
  .description-header {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	gap: 75px;
  
	@include desktop {
	  gap: 55px;
	}
  }
  
  .description {
	text-align: center;
	padding: 35px 0;
	width: 100%;
  
	@include desktop {
	  text-align: start;
	  padding: 25px 0 40px;
	}
  }
  
  .properties-list {
	display: grid;
	grid-template-columns: repeat(2, minmax(100px, 1fr));
	gap: 10px;
	padding: 0 12px;
	list-style-type: none;
  
	&__item {
	  display: grid;
	  grid-template-columns: minmax(auto, 100px) 13px 1fr;
	  gap: 13px;
	  padding-bottom: 8px;
	}
  
	&__item-values {
	  margin-left: 17px;
	}
  
	@include desktop {
	  padding: 0;
  
	  &__item {
		grid-template-columns: minmax(100px, 200px) 70px 1fr;
		padding-bottom: 13px;
	  }
	}
  }
  
  .buttons-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin-top: 27px;
  
	gap: 0 13px;
  
	@include desktop {
	  flex-wrap: wrap;
	  gap: 10px 13px;
	  margin-top: 50px;
	}
  }