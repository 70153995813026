@import "/src/styles/scss/breakpoints";
@import "../../../../../../../styles/scss/variables";
@import "../../../../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  position: relative;
}

.breadcrumbs {
  width: 100%;
  text-overflow: ellipsis;

  &-skeleton {
    height: 28px;
    width: 100%;
    margin: 0px;
  }
}

.header__additional {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;

  @include commonText(500);
  color: $textBlack;
  white-space: nowrap;

  @include mobile {
    gap: 40px;
  }

  @include tablet {
    width: auto;
    gap: 50px;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    gap: 8px;

    user-select: none;
    cursor: pointer;

    @include mobile {
      padding-left: 30px;
    }

    &_title {
      &-skeleton {
        margin: 0px 5px;
        height: 30px;
      }
    }

    &:nth-child(2) {
      margin: 0 10px;
    }
  }
}

.header__share {
  display: flex;
  align-items: center;

  @include commonText(500);
  color: $textBlack;
  white-space: nowrap;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    user-select: none;
    cursor: pointer;
    }

    &:nth-child(2) {
      margin: 0 10px;
    }
}

.social_container {
  position: relative;

  &__popup {
    box-sizing: border-box;
    position: absolute;
    top: 20px; 
    left: -215px; 
    padding: 4px;
    align-items: baseline;
    z-index: 1;
    height: auto;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: $white;
    cursor: auto;
    display: flex;

    @include mobile {
      align-items: center;
      top: 20px;
      left: -280px;
    }

    &__item {
      width: 30px;
      font-size: 8px;
      padding: 3px;
      margin: 3px;
      display: flex;

      @include mobile {
        width: 40px;
        font-size: 10px;
        padding: 5px;
        margin: 5px;
      }

      &:hover {
        background-color: $lightSilver;
        cursor: pointer;
      }

      &_img {
        width: 25px;
        height: 25px;

        @include mobile {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.star-rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $primary;
  margin-right: 25px;

  &__list {
    margin-left: 25px;
  }

  &__item {
    margin-right: 15px;
    cursor: pointer;
  }

  &__button {
    @include commonText(500);
    color: $textBlack;
    padding-bottom: 3px;
  }
}
