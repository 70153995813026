@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  border-bottom: 1px solid $textLighterGrey;
  padding: 75px 0 40px;

  width: 100%;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    padding: 75px 0;
  }
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}