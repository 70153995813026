@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1800px;
  width: 100%;
  margin-bottom: 40px;

  @include desktop {
    margin: 90px auto;
  }
}

.see-more {
  margin-top: 26px;


  @include desktop {
    margin-top: 39px;
  }
}