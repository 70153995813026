@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

:root {
  --fs-small: clamp(2.2rem, 7.8vw + 1rem, 7.5rem);
  --fs-large: clamp(5rem, 5vw + 1rem, 7.5rem);
}

.root {
  position: relative;
  padding: 30px;
  margin-top: 90px;

  @include tablet {
    padding: 50px;
  }

  @include desktop {
    padding: 80px;
  }

  @media (min-width: 1200px) {
    padding: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 57px;
  }

  @media (min-width: 1920px) {
    max-width: 1920px;
    max-height: 1265px;
    height: 1265px;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/backgroundProfit.png");
    background-size: cover;
    background-position: center;
    z-index: -2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(64px);
    z-index: -1;
  }
}

.banner {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;

  @include tablet {
    margin-bottom: 37px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 0;
  }

  @include largeDesktop {
    flex: 1;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__image {
    width: 100%;
    height: auto;
    position: relative;

    @media (min-width: 1920px) {
      max-width: 900px;
      max-height: 1007px;
    }
  }

  &__loading {
    width: 100%;
    height: calc(100vw * 1.119);
    position: relative;

    @media (min-width: 1200px) {
      height: 700px;
    }

    @include largeDesktop {
      height: 800px;
    }

    @media (min-width: 1920px) {
      width: 900px;
      height: 1007px;
    }
  }

  &__text {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    text-transform: uppercase;
    white-space: nowrap;
    text-shadow: 0 1.71px 31.57px rgba(132, 47, 26, 0.3);

    @include tablet {
      top: 30px;
      left: 30px;
      right: 30px;
      bottom: 30px;
    }

    @include desktop {
      top: 40px;
      left: 40px;
      right: 40px;
      bottom: 40px;
    }

    @include largeDesktop {
      left: 20px;
      right: 20px;
    }

    @media (min-width: 1920px) {
      top: 100px;
      left: 145px;
      right: 95px;
      bottom: 100px;
    }

    &-top {
      @include customizableText(800, var(--fs-small), auto);

      @include tablet {
        @include customizableText(800, 6rem, auto);
      }

      @media (min-width: 1200px) {
        @include customizableText(800, var(--fs-large), auto);
      }

      @media (min-width: 1920px) {
        font-size: 7.5rem;
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      @include customizableText(800, var(--fs-small), auto);

      @include tablet {
        @include customizableText(800, 5.5rem, 131px);
      }

      @media (min-width: 1200px) {
        @include customizableText(800, var(--fs-large), 131px);
      }

      @media (min-width: 1920px) {
        font-size: 7.25rem;
      }
    }
  }
}

.products {
  display: flex;
  flex-direction: column;
  margin-right: 0;

  @media (min-width: 1920px) {
    margin-right: 74px;
  }

  &__title {
    text-align: left;
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 24px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    justify-items: center;
    align-items: center;

    @media (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include tablet {
      margin: 0 auto;
      width: 100%;
      max-width: 748px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__card {
    width: 100%;
    max-width: 365px;
    max-height: 500px;
    box-sizing: border-box;
    background-color: white;
    padding: 16px;

    @include desktop {
      max-height: 375px;
    }

    @include largeDesktop {
      max-height: 495px;
    }
  }
  
}

.custom-heart-position {
  position: absolute;
  right: -40px;
  top: -40px;
  cursor: pointer;
  transform: translate(50%, 50%);

  @media (min-width: 360px) and (max-width: 599px) {
    right: -80px;
  }

  @media (min-width: 600px) and (max-width: 1199px) {
    right: -60px;
  }


  @media (min-width: 1200px) {
    right: 0px;
  }
}
