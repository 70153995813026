@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.brand-title {
  @include customizableText(500, 24px, 29px);
  color: $textBlack;

  @include desktop {
    @include bigTitle();
  }
}

.main-title {
  @include additionalText();
  color: $textBlack;
  margin-top: 13px;
  margin-bottom: 20px;

  text-align: center;

  &--colored {
    color: $primary;
  }

  @include desktop {
    @include customizableText(500, 24px, 29px);
    text-align: start;
    margin-top: 20px;
  }

  &-skeleton {
    height: 30px;
    width: 100%;
  }
}

.description {
  @include customizableText(500, 14px, 17px);
  color: $sonicSilver;
  margin-bottom: 17px;

  text-align: center;

  @include desktop {
    @include commonText(500);
    color: $darkgrey;
    margin-bottom: 20px;

    text-align: start;
  }
}

.delivery-date {
  @include customizableText(500, 12px, 15px);
  color: $textBlack;

  width: fit-content;

  margin-bottom: 17px;
  border: 1px solid $textLighterGrey;
  border-radius: 3px;
  padding: 4px 20px;

  @include desktop {
    @include customizableText(500, 14px, 17px);

    margin-bottom: 20px;
    padding: 4px 7px;
  }
}

.promotion-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include additionalText();
  color: $textBlack;

  &__price-promo {
    margin-right: 11px;

    &-skeleton {
      margin-right: 10px;
      margin-top: 0px;
      height: 25px;
      width: 80px;
    }
  }

  &__price-main {
    color: $textLighterGrey;
    text-decoration: line-through;

    margin-right: 15px;

    &--available {
      color: $textBlack;
      text-decoration: none;
    }
  }

  &__percent {
    @include customizableText(500, 14px, 17px);
    background-color: $darkBlue;
    color: $white;

    border-radius: 3px;
    padding: 2px 14px;
  }

  @include desktop {
    @include customizableText(500, 24px, 29px);

    margin-bottom: 20px;

    &__price-main {
      color: $textLightGrey;
    }

    &__percent {
      @include commonText(500);
    }
  }
}
