@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.card__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  max-width: 365px;
  width: 100%;

  @include desktop {
    max-width: 365px;

    &:hover .card {
      justify-content: flex-start;
      min-height: auto !important;
      height: fit-content;

      background-color: $white;

      box-shadow: 0px 0px 30px 13px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      z-index: 3;

      .card {
        &__labels {
          display: none;
        }

        &_img-wrapper {
          max-width: 267px;
          max-height: 267px;
          margin: 0 0 9px;
          order: 1;
        }

        &_img {
          width: 0px;

          &-show {
            width: auto;
            max-width: 267px;
            max-height: 267px;
          }
        }

        &__heart_icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &_brand-title {
          display: flex;
          @include commonText(500);
          color: $textBlack;
          margin: 0 -8px 4px;
          order: 2;
        }

        &_description {
          display: flex;
          @include descriptionTextMedium(500);
          margin: 0 -8px 14px;
          order: 3;
        }

        &_amount {
          display: flex;
          justify-content: space-around;
          align-items: center;

          @include commonText(500);
          color: $textBlack;
          margin-bottom: 21px;
          order: 4;

          max-width: 200px;
          width: 100%;

          &-min-content {
            justify-content: center;
          }
        }

        &_title {
          @include customizableText(500, 14px, 17px);
          min-height: 34px;
          margin: 0 -8px 26px;
          order: 5;
        }

        &__price {
          margin-bottom: 34px;
          order: 6;
        }

        &__buttons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          order: 7;
          margin-bottom: 33px;
        }
      }
    }

   &:hover .card::after {
    content: "";
    position: absolute;

    top: -20px;

    max-width: 469px;
    width: calc(100% + 170px);
    height: calc(100% + 40px);
    background-color: $white;

    box-shadow: 0px 0px 30px 13px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: -1;
  }
}

  &:hover .linkInfo {
    display: block !important;
    z-index: 4;
  }
  @media (max-width: 1023px) and (min-width: 300px) {
    &:hover .linkInfo {
      display: none !important;
    }
  }

}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;

  position: relative;
  max-width: 365px;
  width: 100%;

  &__labels {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
  }

  &__empty-label {
    width: 100%;
    height: 25px;
  }

  &__empty-label-row {
    width: 100%;
    height: 25px;
    margin-bottom: 15px;
  }

  &_img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 112px;
    height: 112px;

    margin: 10px 0;

    @include tablet {
      width: 150px;
      height: 150px;
    }

    @include desktop {
      width: 200px;
      height: 200px;

      margin: 15px 0;
    }

    @include largeDesktop {
      width: 300px;
      height: 300px;

      margin: 25px 0;
    }
  }

  &__heart_icon-wrapper {
    position: absolute;
    bottom: calc(100% - 16px);
    right: -36px;
    cursor: pointer;

    @media (min-width: 280px) and (max-width: 340px) {
      top: -20px;
      right: -20px;
    }

    @include largeDesktop {
      right: -25px;
    }
  }

  &_img {
    align-self: center;
    justify-self: center;

    max-width: 0;
    max-height: 0;
    width: auto;
    height: 100%;
    overflow: hidden;

    cursor: pointer;

    &-show {
      width: auto;
      max-width: 112px;
      max-height: 112px;
    }

    &-svg {
      opacity: 0.3;
      animation: 2s animation linear infinite;
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }

    @keyframes animation {
      0% {
        opacity: 0.2;
      }
      40% {
        opacity: 0.9;
      }
      100% {
        opacity: 0.2;
      }
    }

    @include tablet {
      max-width: 0;
      max-height: 0;

      &-show {
        width: auto;
        max-width: 150px;
        max-height: 150px;
      }
    }

    @include desktop {
      width: 0px;

      &-show {
        width: auto;
        max-width: 300px;
        max-height: 300px;
      }
    }
  }

  &_brand-title {
    display: none;
  }

  &_title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: center;

    @include customizableText(500, 12px, 12px);
    color: $textBlack;

    overflow: hidden;
    text-overflow: ellipsis;
    
    height: 36px;
    margin-bottom: 8px;

    @media (min-width: 280px) and (max-width: 340px) {
      width: 132px;
    }

    @include tablet {
      height: 44px;
      -webkit-line-clamp: 2;
      @include customizableText(500, 12px, 15px);
      margin-bottom: 6px;
    }

    @include desktop {
      -webkit-line-clamp: 2;
      @include commonText(500);

      margin: 0 25px 4px;
      height: 40px;
    }
  }

  &_description {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;

    @include customizableText(500, 12px, 12px);
    color: $textLightGrey;

    margin-bottom: 15px;

    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet {
      @include customizableText(500, 12px, 15px);

      margin-bottom: 10px;
    }

    @include desktop {
      @include commonText(500);
      margin: 0 10px 4px;
    }
  }

  &__price {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include customizableText(500, 12px, 12px);
    color: $textBlack;

    max-width: 200px;
    width: 100%;

    margin-bottom: 10px;

    &-min-content {
      justify-content: center;
    }

    @include tablet {
      justify-content: space-between;

      @include customizableText(500, 12px, 15px);
      max-width: 150px;

      margin-bottom: 15px;

      &-min-content {
        justify-content: center;
      }
    }

    @include desktop {
      justify-content: space-between;

      margin-bottom: 25px;

      @include commonText(500);
      max-width: 200px;

      &-min-content {
        justify-content: center;
      }
    }
  }

  &_discount {
    align-self: center;
    justify-self: center;

    color: #fff;
    font-size: 14px;

    background-color: red;

    border-radius: 50px;
    padding: 3px 8px;

    z-index: 0;
  }

  &_amount {
    display: none;
  }

  &__buttons {
    display: none;
  }

  @include tablet {
    max-width: 210px;
  }

  @include desktop {
    max-width: 365px;
  }

  @include desktop {
    align-self: center;
  }
}

.sale-label {
  margin-bottom: 15px;

  @include desktop {
    margin-bottom: 10px;
  }
}

.sale-percent {
  padding: 0;

  padding: 0 4px;

  @include tablet {
    padding: 0 9px;
  }

  span {
    display: block !important;
    margin: 0;
    @include customizableText(500, 12px, normal);

    @include tablet {
      @include commonText(500);
    }
  }
}

.linkInfo {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  @include customizableText(500, 15px, 18px);

  border-bottom: 1px solid $textLightGrey;
  color: $textLightGrey;
  outline: none;
  user-select: none;
  display: none;
  cursor: pointer;
  white-space: nowrap;
}
.newIcon {
  width: 18px;
  height: 18px;
}
.fireIcon {
  width: 17px;
  height: 17px;
}
.saleIcon {
  width: 19px;
  height: 19px;
}