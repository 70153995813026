@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;

  padding: 0 20px;
  margin-top: 100px;
}

.title--margin-top--0 {
  @include customizableText(500, 18px, 21px);
  margin: 0;
  margin-bottom: 20px;
  color: $primary;

  @include tablet {
    @include customizableText(500, 20px, 24px);
  }

  @include desktop {
    @include customizableText(500, 24px, 28px);
    margin-bottom: 25px;
  }
}

.title {
  @include customizableText(500, 18px, 21px);
  margin-bottom: 20px;
  color: $primary;

  @include tablet {
    @include customizableText(500, 20px, 24px);
  }

  @include desktop {
    @include customizableText(500, 24px, 28px);
    margin-bottom: 25px;
  }

  & > img {
    height: 16px;
    width: 14px;

    margin-right: 10px;

    @include tablet {
      height: 26px;
      width: 24px;

      margin-right: 20px;
    }
  }
}

.item_link {
  color: $primary;
}