@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";

.dots {
  display: flex;
  justify-content: center;
  align-items: center;

  justify-self: center;
  align-self: flex-start;
  gap: 12px;

  @include tablet {
    gap: 36px;
  }

  @include largeDesktop {
    gap: 60px;
  }

  &__dynamical-wrapper {
    justify-content: flex-start;
    overflow: hidden;
    height: 60px;
  }
}

.dot-wrapper {
  padding: 5px;

  @include tablet {
    padding: 10px;
  }

  @include desktop {
    padding: 20px;
  }
}

.dot {
  justify-self: center;
  align-self: flex-start;
  margin: 0 auto;

  width: 14px;
  height: 14px;
  border-radius: 14px;

  background-color: $secondary;

  &--regular {
    margin: 2px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    transition:
      width 0.3s ease-in,
      height 0.3s ease-in;
  }

  &--small {
    margin: 4px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    transition:
      width 0.3s ease-in,
      height 0.3s ease-in;
  }

  &--active {
    background-color: var(--active-color) !important;
    transition:
      width 0.3s ease-in,
      height 0.3s ease-in,
      background-color 0.3s ease;
  }
}
