@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.root {
  @include commonText(500);

  border-radius: 5px;
  padding: 12px 15px;

  color: $white;
  background-color: $primary;

  outline: none;
  -webkit-user-select: none;
  user-select: none;

  cursor: pointer;
}

.white {
  color: $textBlack;
  border: 1px solid $textBlack;
  background: $white;
}

.black {
  color: $white;
  border: 1px solid $textBlack;
  background: $textBlack;
}

.disable {
  cursor: not-allowed;
  opacity: 0.5;
}
