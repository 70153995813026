@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.pay {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  gap: 20px;
  background-color: $bleachedSilk;

  margin-top: 100px;
  border-radius: 10px;

  @include desktop {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;
  }

  &__info-block {
    width: 100%;

    @include desktop {
      grid-row: 1;
      grid-column: span 2;
    }
  }

  &__method {
    width: 100%;

    @include desktop {
      grid-row: 2;
      grid-column: 1;
    }
  }

  &__recipient-and-products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;

    @include desktop {
      grid-row: 2;
      grid-column: 2;
    }
  }
}
