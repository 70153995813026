@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.slider-container {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  @include tablet {
    padding: 0 40px;
  }

  @include largeDesktop {
    padding: 0 20px;
  }

  @media (min-width: 1520px) {
    padding: 0;
  }
}

.slider {
  max-width: 1520px;
  margin: 0 auto;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.single {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  @include tablet {
    padding: 0 40px;
  }

  @include largeDesktop {
    padding: 0 20px;
  }

  @media (min-width: 1520px) {
    padding: 0;
    max-width: 1520px;
  }

  &__slider {
    max-width: 1520px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 20px;
  }

  &__slide {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  &__banner-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
  }
}

.slider-dots {
    gap: 14px;

    @include desktop {
      gap: 4px;
    }
}

.slider-dot {
  width: 16px;
  height: 16px;
  border-radius: 16px;

  background-color: rgba(0, 0, 0, 0.2);
}
