@import "../../../styles/scss/variables";
@import "../../../styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 280px;
  width: 100%;
}

.search-icon {
  width: 40px;
  height: 40px;

  padding: 7px;
  cursor: pointer;
}

.personal-aria-icon {
  padding: 2px 4px;
  cursor: pointer;
}

.cart {
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  &_icon {
    margin-right: 10px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @include commonText(500);
    color: $textLighterGrey;
  }
}