@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;

  max-width: 513px;
  width: 100%;

  margin: 35px 0;

  @include tablet {
    flex-direction: column;
    margin: 50px 0;
  }

  @include desktop {
    flex-direction: column;

    margin: 90px 0 0;
    padding: 0 10px;
  }
}

.subscribe {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  &_title {
    @include commonText(500);
    color: $textBlack;

    text-align: center;
    margin-bottom: 35px;
  }

  &_input {
    max-width: 400px;
    width: 100%;

    @include commonText(500);
    color: $textBlack;

    border-bottom: 1px solid $textLighterGrey;
    padding-bottom: 6px;

    &::placeholder {
      @include descriptionTextBig;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__description {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin: 10px 0 25px;

    &_checkbox {
      position: relative;

      min-width: 15px;
      min-height: 15px;

      margin-right: 6px;
      border: 1px solid $textLighterGrey;
      border-radius: 3px;

      user-select: none;
      cursor: pointer;

      & > img {
        width: 12px;
        height: 12px;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }

    &_law {
      @include descriptionTextMedium(400);
      color: $textLightestGrey;

      max-width: 383px;
      width: 100%;
    }
  }

  &__button {
    padding: 12px 22px;
  }

  @include tablet {
    align-items: flex-start;
    margin-bottom: 40px;

    &_title {
      @include customizableText(500, 14px, 17px);
      text-align: start;
    }
  }

  @include desktop {
    align-items: flex-start;
    margin-bottom: 76px;

    &_title {
      text-align: start;
      margin-bottom: 42px;
    }

    &__description {
      margin: 10px 0 21px;
    }

    &__button {
      padding: 12px 15px;
    }
  }
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include commonText(500);
  color: $textBlack;

  width: 100%;

  margin-bottom: 50px;

  @include desktop {
    gap: 20px;
  }

  &__schedule {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin-bottom: 35px;

    &_title {
      margin-bottom: 21px;

      @include desktop {
        white-space: nowrap;
      }
    }

    &_item {
      margin-bottom: 6px;
    }

    @include tablet {
      align-items: flex-start;
    }

    @include desktop {
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  &__social-media {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &_title {
      margin-bottom: 15px;
    }

    &__icons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      &_link {
        margin-right: 10px;
      }

      &_icon {
        cursor: pointer;
      }
    }
  }

  @include tablet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @include customizableText(500, 14px, 17px);

    margin-bottom: 46px;

    &__social-media {
      &__icons {
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: 70px;

    &__social-media {
      &_title {
        margin-bottom: 20px;
      }
    }
  }
}

.cities {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include commonText(500);

  margin-bottom: 50px;

  &_title {
    color: $textBlack;
    text-align: center;
    margin-bottom: 15px;
  }

  @include tablet {
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0;

    &_title {
      @include customizableText(500, 14px, 17px);
      text-align: start;
      margin-bottom: 19px;
    }
  }

  @include desktop {
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0;

    &_title {
      text-align: start;
      margin-bottom: 21px;
    }
  }
}
