@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  min-height: calc(100vh - 400px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__img {
    margin-top: 80px;
    margin-bottom: 40px;
    width: 70%;

    @include desktop {
      margin-bottom: -50px;
      width: auto;
      margin-top: 0px;
    }
  }

  &__title {
    @include additionalText;
    margin-bottom: 80px;
    text-align: center;

    @include desktop {
      @include bigTitle;
      margin-bottom: 150px;
    }
  }

  &__link {
    @include additionalText;
    margin-bottom: 80px;
    text-align: center;
  }
}
