@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 177px;
    margin-bottom: 73px;
  }
}

.root {
  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    padding: 0 100px;
    margin-bottom: 180px;
  }
}

.title {
  @include customizableText(500, 18px, 21px);
  margin-bottom: 20px;
  color: $primary;

  @include tablet {
    @include customizableText(500, 20px, 24px);
  }

  @include desktop {
    @include customizableText(500, 24px, 28px);
    margin-bottom: 25px;
  }

  & > img {
    height: 16px;
    width: 14px;

    margin-right: 10px;

    @include tablet {
      height: 26px;
      width: 24px;

      margin-right: 20px;
    }
  }
}

.text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @include customizableText(400, 14px, 21px);

  margin-bottom: 10px;

  @include tablet {
    @include customizableText(400, 16px, 24px);
  }

  @include desktop {
    @include customizableText(400, 18px, 29px);

    margin-bottom: 20px;
  }

  div > span {
    margin-right: 5px;
  }

  div > a {
    text-decoration: underline;
  }
}

.link {
  color: $primary;
}

.phone_number {
  font-weight: 500;

  &:first-child {
    margin-bottom: 20px;
  }
}

.adress {
  display: block;
}

.route-link {
  align-self: center;
  @include customizableText(500, 16px, 21px);

  color: $primary;
  text-decoration: underline !important;

  @include tablet {
    @include customizableText(500, 20px, 24px);
  }

  @include desktop {
    @include customizableText(500, 24px, 28px);
  }
}

.category,
.organization {
  display: none;
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}