@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.link {
  @include commonText(500);
  color: $primary;

  text-align: center;

  border-bottom: 1px solid $primary;
  padding-bottom: 2px;

  cursor: pointer;

  &--activated {
    color: $lighterBlack;
    border-bottom: 1px solid $lighterBlack;
    cursor: not-allowed;
  }

  @include desktop {
    text-align: start;
  }
}