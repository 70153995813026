@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.schedule {
  @include customizableText(400, 14px, 16px);
  color: $lighterBlack;

  width: 100%;

  border-radius: 5px;
  padding: 16px 20px 23px;

  &__head {
    padding-bottom: 20px;
  }

  &__head,
  &__body {
    display: grid;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid $darkgrey;

    &:last-child {
      border-bottom: none;
    }
  }

  .row {
    &__main-title {
      @include commonText(500);
      color: $textBlack;

      text-align: start;

      &:last-child {
        text-align: end;
      }
    }

    &__subtitle {
      @include customizableText(500, 14px, 17px);
      border-right: 1px solid $textLighterGrey;
      padding: 3px 0;
    }

    &__time {
      @include customizableText(500, 14px, 17px);
      text-align: end;
      padding: 3px 0;
    }

    &__weekend {
      color: $primary;
    }
  }
}
