@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.row {
  &__input {
    &__popup {
      box-sizing: border-box;
      align-items: center;
      position: absolute;
      top: 26px;
      z-index: 10;
      height: auto;
      box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
      border-radius: 1px;
      cursor: auto;
      display: flex;
      background-color: $darkWhite;
      flex-direction: column;
      width: 100%;
      max-height: 130px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: hsl(0 0% 20%);

      &__item {
        width: 100%;

        &-btn {
          padding: 5px 0px;
          width: 100%;
        }
      }
    }
  }
}
