@import "../../styles/scss/variables";
@import "../../styles/scss/breakpoints";
@import "../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 15px;

  background-color: $white;
}

.avatar {
  width: 55px;
  height: 55px;
  margin-bottom: 15px;
}

.user-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @include additionalText();
  color: $textBlack;

  & > span:nth-child(2) {
    padding: 0 5px;
  }
}

.links-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 19px;
}

.link {
  position: relative;

  @include commonText(500);
  color: $textBlack;

  padding: 11px 0;

  &--active {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 7px;
      width: 100%;
      height: 1px;
      background-color: $textBlack;
    }
  }
}

.button {
  max-width: 190px;
  width: 100%;

  margin-top: 19px;
  padding: 15px 14px;
}
