@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.info-danger-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 15px;
  padding: 20px;
  background-color: $transparentPink;
  margin-bottom: 40px;

  @include tablet {
    margin-bottom: 30px;
  }

  @include desktop {
    margin-bottom: 30px;
  }

  &__message {
    @include customizableText(600, 16px, 22px);
    color: $dangerInfoText;
  }
}
