@import "/src/styles/scss/breakpoints";
@import "../../../../styles/scss/variables";
@import "../../../../styles/scss/mixins/typography";

.root {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}

.breadcrumbs {
  width: 100%;
  text-overflow: ellipsis;

  &-skeleton {
    height: 28px;
    width: 100%;
    margin: 0px;
  }
}

.header__additional {
  display: flex;
  align-items: center;
  padding-left: 80px;
  gap: 30px;

  @include commonText(500);
  color: $textBlack;
  white-space: nowrap;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    gap: 8px;

    user-select: none;
    cursor: pointer;

    &_title {
      &-skeleton {
        margin: 0px 5px;
        height: 30px;
      }
    }

    &:nth-child(2) {
      margin: 0 10px;
    }
  }
}

.header__share {
  display: flex;
  align-items: center;

  @include commonText(500);
  color: $textBlack;
  white-space: nowrap;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    user-select: none;
    cursor: pointer;
    }

    &:nth-child(2) {
      margin: 0 10px;
    }
}

.social_container {
  position: relative;

  &__popup {
    box-sizing: border-box;
    align-items: center;
    position: absolute;
    top: 30px;
    left: -150px;
    z-index: 1;
    height: auto;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor: auto;
    display: flex;

    &__item {
      width: 40px;
      font-size: 10px;
      padding: 5px;
      margin: 5px;
      display: flex;

      &:hover {
        background-color: $lightSilver;
        cursor: pointer;
      }

      &_img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.star-rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $primary;
  margin-right: 25px;

  &__list {
    margin-left: 25px;
  }

  &__item {
    margin-right: 15px;
    cursor: pointer;
  }

  &__button {
    @include commonText(500);
    color: $textBlack;
    padding-bottom: 3px;
  }
}
