@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;

  max-width: 900px;
  width: 100%;
  height: 580px;

  border-radius: 5px;
  padding: 0 28px;

  box-shadow: 0px 0px 30px 13px rgba(0, 0, 0, 0.25);
  background-color: $white;
  transform: translate(-50%, -50%);
  z-index: 1001;

  &_img-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;

    &-img {
      max-width: 380px;
      min-width: 180px;
      max-height: 500px;
      height: auto;
      width: auto;
      align-self: center;
    }
  }
}

.logo {
  max-width: 380px;
  width: 100%;
  height: auto;
  align-self: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  padding: 77px 0 18px 28px;
}

.info {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 25px;
}

.offers__list-wrapper {
  position: relative;
  height: 100%;
  margin: 0px;

  // TODO: Update after test
  & > :nth-child(2) > :first-child {
    position: absolute;
  }
}

.offers__list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;
}

.description {
  @include customizableText(500, 24px, 29px);
  color: $textBlack;

  margin-bottom: 8px;
}

.title {
  @include customizableText(500, 15px, 18px);
  color: $textLightGrey;

  padding-bottom: 25px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  &__add {
    width: 200px;
  }
}

.background {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);

  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 10;
}