@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.avatar {
	align-self: center;
	justify-self: center;

	max-width: 112px;
	max-height: 112px;
	width: 300px;
	height: 100%;

	@include tablet {
		max-width: 150px;
		max-height: 150px;
	}

	@include desktop {
		max-width: 300px;
		max-height: 300px;
	}

	background-color: #ccc;
	border-radius: 1%;
	margin: 8px;

	background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
	background-size: 200% 200%;
	animation: gradient 3.6s infinite;
}

@keyframes gradient {
	0% {
		background-position-x: 100%;
	}

	100% {
		background-position-x: -100%;
	}
}
