@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    max-width: 1537px;
    width: 100%;
  
    margin: 0 auto;
    padding: 170px 10px;
}

.title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  
    position: relative;
  
    width: 100%;
}

.link {
    position: absolute;
    top: -100%;
    left: 0;
    margin-left: 10px;
  
    @include customizableText(500, 14px, 17px);
    color: $textLightGrey;
  
    transform: translateY(-50%);
    @include desktop {
      top: 50%;
    }
}

.status-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    background-color: $bleachedSilk;
  
    margin-top: 100px;
    border-radius: 10px;
}
