@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.dob-button {
  position: relative;
  max-width: 277px;
  width: 100%;
  text-align: center;

  border-bottom: 1px solid $textLightGrey;
  padding-bottom: 8px;

  &--disabled {
    cursor: not-allowed;
  }

  &__title {
    @include commonText(500);
    color: $textLighterGrey;

    &--selected {
      color: $textBlack
    }
  }

  &__date-input {
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }

  &::placeholder {
    text-align: center;
  }
}

.container {
  display: flex;
  justify-content: center;
  margin: 10px;

  &__arrow-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
 }
  
  &__icon {
    width: 16px;
    height: 16px;
  }

}
