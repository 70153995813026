@import "/src/styles/scss/_variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: center;

  width: 100%;

  margin: 0 auto;
  padding: 55px 20px;
  gap: 20px;

  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include desktop {
    max-width: 1156px;

    padding: 90px 0;
    gap: 60px;

    overflow: visible;
  }
}

.story-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: 0;
}

.story {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 76px;
  height: 76px;

  background-color: transparent;
  border: 2px solid $primary;
  border-radius: 50%;
  
  cursor: pointer;

  &_title {
    @include commonText(500);
    color: $textBlack;

    margin-top: 29px;
  }

  @include desktop {
    width: 183px;
    height: 183px;

    border: 4px solid $primary;
  }
}