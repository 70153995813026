$primary: #C73C87;
$secondary: rgba(165, 0, 109, 0.3);
$lightPink: rgba(165, 0, 109, 0.21);
$fireIsland: #dc4b38;
$darkBlue: #191B38;
$darkgrey: #797979;
$grey: #838383;
$lightGrey: #AEAEAE;
$lighterGrey: #C6C6C6;
$lightSilver: #D8D8D8;
$gainsboro: #DFDFDF;
$superSilver: #EEEEEE;
$sonicSilver: #7A7A7A;
$tintGrey: #7D7D7D;
$athensGray: #F6F6F7;
$white-smoke: #F5F5F5;
$bleachedSilk: #f4f3f3;
$concrete: #F2F2F2;
$darkWhite: #F1F1F1;
$white: #FFFFFF;
$transparentPink: #FFF4FB;
$greyish: #9C9C9C;

//text colors
$textBlack: #000000;
$lighterBlack: #1B1B1B;
$textGrey: #636363;
$textLightGrey: #646464;
$textLighterGrey: #9E9E9E;
$textLightestGrey: #8B8B8B;
$dangerInfoText: #C0538E;