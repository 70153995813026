@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.loading {

    span {
      display: inline-block;
      animation: scaleUp .7s infinite alternate;
    }
}

@keyframes scaleUp {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
}