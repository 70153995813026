@import "../../../../styles/scss/variables";
@import "../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: 0 auto;
  padding-top: 172px;
  padding-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include bigTitle();
  color: $lighterGrey;

  max-width: 660px;
  width: 100%;

  border-bottom: 1px solid $lighterGrey;

  &_tab {
    box-shadow: none;
    padding-bottom: 8px;

    &--active {
      color: $textBlack;
      box-shadow: inset 0 -2px $textBlack;
      transition: box-shadow 0.2s linear;
    }
  }
}