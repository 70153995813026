@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 502px;
  width: 100%;
  margin: 0 auto;
  padding: 107px 20px 85px;

  @include desktop {
    padding: 147px 10px 166px;
  }
}

.title {
  @include additionalText;
  color: $textBlack;
  margin-bottom: 40px;

  @include desktop {
    @include bigTitle;
  }
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 30px 0;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;

    gap: 0;
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  width: 100%;
  height: 52px;

  @include desktop {
    width: 228px;
  }
}