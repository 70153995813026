@import "../../../../styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: 0 auto;
}

.preview-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  width: 100%;
}

.preview {
  width: 100%;

  @include desktop {
    max-width: 480px;
  }

  @media (min-width: 1100px) {
    max-width: 680px;
  }
}

.offers-wrapper {
  width: 40%;
  min-width: 450px;
  max-width: 600px;
  margin-left: 40px;

  &-article {
    @include commonText(500);
    margin-bottom: 10px;
  }
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
