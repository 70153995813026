@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.slider-wrapper {
  width: 100%;
  overflow: hidden;

  @include mobile {
    margin-top: 0;
  }
}

.slider {
  width: 100%;
  position: static;
}

.content {
  margin: 45px 0px;
  @include mobile {
    margin: 55px 0px;
  }
  @include tablet {
    margin: 70px 0px;
  }
  @include desktop {
    max-width: 1250px;
    margin: 70px auto;
    padding: 0;
    z-index: 10;

    overflow: visible !important;
  }

  @include largeDesktop {
    margin: 100px auto;
    max-width: 1560px;
  }
}

.grouped-cards {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  overflow: visible !important;
  justify-content: space-around;

  @include desktop {
    height: 480px;
  }

  @include largeDesktop {
    padding: 0 60px;
  }
}

.card-wrapper {
  width: 150px;

  @include tablet {
    width: 150px;
  }

  @include desktop {
    width: 300px;
  }

  @include largeDesktop {
    width: 300px;
  }
}

.pagination {
  grid-template-columns: auto 138px auto;
  align-self: center;
  margin-top: 0;

  @include mobile {
    grid-template-columns: auto 164px auto;
    max-width: 471px;
  }

  @include tablet {
    grid-template-columns: auto 138px auto;
    max-width: 471px;
  }
}

.dots {
  & > button {
    width: 10px;
    height: 10px;
  }

  @include desktop {
    & > button {
      width: 16px;
      height: 16px;
    }
  }
}
