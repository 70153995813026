@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.card {
  width: 100%;
  max-width: 320px;
  height: 425px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @include tablet {
    width: 230px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.custom-avatar {
  max-width: 320px;
  max-height: 230px;
  width: 320px;
  height: 230px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0;

  @include tablet {
    width: 230px;
  }
}
