@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: 25px;
  background-color: $darkBlue;

  border-radius: 3px;
  padding: 4px 8px;

  @include desktop {
    padding: 6px 10px;
  }
}

.title {
  display: none;
  @include descriptionTextSmall;
  color: $white;
  margin-left: 7px;

  @include tablet {
    display: block;
  }
}