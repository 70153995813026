@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.cart-product {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 10px;
  width: 100%;
  border-radius: 10px;

  &::after {
    content: "";
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 0;
    height: 1px;
    background-color: $textLighterGrey;
  }

  &--not-available {
    max-width: calc(100% - 10px);
    background-color: $concrete;

    &::after {
      left: 5px;
      right: 5px;
    }
  }

  &__image-and-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__image-and-button-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include mobile {
      max-width: 810px;
    }

    @include tablet {
      max-width: 300px;
    }

    @include desktop {
      max-width: 200px;
    }
  }

  &__image-wrapper {
    display: block;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }

  &__image {
    display: block;
    width: 100px;
    height: 100px;
    object-fit: contain;

    &--not-available {
      filter: contrast(0.9) grayscale(100%);
    }
  }

  &__info-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: max-content;
  }

  @include desktop {
    padding: 40px 30px;

    &::after {
      left: 30px;
      right: 30px;
    }

    &--not-available {
      max-width: calc(100% - 40px);

      &::after {
        left: 10px;
        right: 10px;
      }
    }
  }
}
