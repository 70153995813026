@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.slider {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.radio {
  display: none;
}

.inner_slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  pointer-events: none;
}

.btn {
  all: unset;
  width: 14%;
  pointer-events: auto;
  position: absolute;
  z-index: 25;
  top: 0;
  height: 100%;

  &__left {
    left: 0;
    &:hover {
      cursor:
        url("../../../assets/icons/svg/arrow-left.svg") 16 16,
        auto;
    }
  }

  &__right {
    right: 0;
    &:hover {
      cursor:
        url("../../../assets/icons/svg/arrow.svg") 16 16,
        auto;
    }
  }
}

label {
  cursor: pointer;
  text-decoration: none;
}

.slides {
  position: relative;
  z-index: 1;
}

.overflow {
  width: 100%;
  overflow: hidden;
}

.inner {
  display: flex;
  transition: margin-left 800ms cubic-bezier(0.77, 0, 0.175, 1);
  width: calc(100% * var(--slides-count));
  line-height: 0;
  height: 100%;
  margin-left: -100%;
}

.slide {
  width: calc(100% / var(--slides-count));
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slide_link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

.slide_content {
  position: relative;
  width: 100vw;
  height: 0;
  padding-bottom: 46.5%;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  width: 100%;
  gap: 10px;

  outline: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  touch-action: manipulation;

  @include tablet {
    justify-content: flex-start;
    padding-left: 57px;
  }

  @include desktop {
    position: absolute;
    bottom: 10.5%;
    left: 60%;
    transform: translateX(-50%);
    margin: 0;
    padding-left: 0;
    z-index: 4;
  }

  &__wrapper {
    position: relative;
    width: var(--bullet-width);
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include desktop {
      width: var(--bullet-width);
    }
  }

  &__label {
    display: inline-block;
    margin: 0 5px;
    width: var(--bullet-width);
    height: 3px;
    background-color: $secondary;
    box-sizing: border-box;

    @include desktop {
      width: var(--bullet-width);
      height: 3px;
    }
  }

  &__label.active {
    background-color: $primary;
  }
}

.text_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 15px 12px;
  z-index: 20;
  min-height: 67px;

  @include tablet {
    align-items: flex-start;
    padding-left: 67px;
  }

  @include desktop {
    position: absolute;
    bottom: 14.5%;
    left: 10.5%;
    padding-left: 0;
  }

  &__title {
    @include commonText(500);
    color: $textBlack;
    white-space: nowrap;

    @include desktop {
      @include bigTitle;
      overflow: hidden;
      text-overflow: ellipsis;

      &--white {
        color: $white;
      }
    }
  }

  &__subtitle {
    @include commonText(500);
    color: $textBlack;
    min-height: 40px;

    @include desktop {
      @include bigTitle;
      overflow: hidden;
      text-overflow: ellipsis;

      &--white {
        color: $white;
      }
    }
  }
}
