@import "../../styles/scss/variables";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  max-width: 1660px;
  width: 100%;

  overflow: hidden;
}

.slider {
  max-width: 1430px;
  width: 100%;

  & div ul {
    display: flex !important;
    justify-content: center;
    align-items: center;

    bottom: -34px;
    text-align: center;
    gap: 13px;

    & li {
      width: 16px;
      height: 16px;

      border-radius: 50%;

      background: $secondary;

      & button {
        background: transparent;
  
        width: 16px;
        height: 16px;
        
        padding: 0;

        cursor: pointer;
      }

      & button::before {
        content: '';

        width: 0;
        height: 0;

        cursor: pointer;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 471px;
  max-height: 74px;

  width: 100%;
  height: 100%;
}