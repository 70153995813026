@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/variables";

.modal-image {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  z-index: 9999;
  top: 0;
  left: 0;

  &:hover {
    cursor: pointer;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: -1;
  }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s ease;

    & > span {
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      border-radius: 4px;
      background-color: $primary;

      &:first-child {
        left: 0;
        transform: rotate(45deg);
      }

      &:last-child {
        left: 0;
        transform: rotate(-45deg);
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__image {
    display: block;
    width: 100%;
    max-width: 90dvw;
    height: 50dvh;
    object-fit: contain;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 200% 200%;
    animation: gradient 3.6s infinite;

    @include tablet {
      max-width: 90dvw;
      height: 70dvh;
    }

    @include desktop {
      max-width: 70dvw;
      height: 60dvh;
    }

    @include largeDesktop {
      max-width: 50dvw;
      height: 70dvh;
    }

    &:hover {
      cursor: default;
    }

    &--loaded {
      background-image: none;
      animation: none;
      max-width: fit-content;
    }
  }
}

@keyframes gradient {
  0% {
    background-position-x: 100%;
  }

  100% {
    background-position-x: -100%;
  }
}
