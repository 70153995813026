@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1308px;
  width: 100%;

  margin: 0 auto;
  padding: 85px 0;
  padding-bottom: 0;
  padding-top: 40px;

  @include desktop {
    padding: 90px 0;
    padding-bottom: 20px;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: max-content;
  width: 100%;

  padding: 35px 20px 0;
  gap: 32px;

  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet {
    grid-template-columns: repeat(4, 1fr);
  }

  @include desktop {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    padding-top: 100px;
    overflow: visible;
  }
}

.popular-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.popular-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.popular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 150px;
  background-color: #e9e9f6;
  border-radius: 50%;
  border: 0;
  cursor: pointer;

  @include desktop {
    width: 250px;
    height: 250px;
  }

  &_title {
    @include commonText(500);
    color: $textBlack;

    margin-top: 12px;
    text-align: center;

    @include desktop {
      margin-top: 24px;
    }
  }
}