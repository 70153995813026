@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  border-top: 1px solid $tintGrey;
  padding: 35px 20px 0;

  &--deleted {
    background-color: $concrete;
  }

  @include desktop {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    margin-top: 0;
    padding: 17px 0;
    padding-right: 37px;
  }
}

.title {
  @include additionalText();
  color: $textBlack;
  margin-bottom: 75px;
}

.logo {
  max-width: 190px;
  max-height: 190px;
  width: 100%;
  height: 100%;

  &--deleted {
    filter: contrast(0.9) grayscale(100%)
  }
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: 35px 0 40px;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
  }

  &__title {
    @include commonText(500);
    color: $textBlack;
    text-align: center;

    margin-bottom: 18px;
  }

  &__vendor-code {
    @include descriptionTextMedium(500);
    color: $textLightGrey;
    margin-bottom: 35px;
  }

  &__link {
    @include commonText(500);
    color: $primary;
    padding: 0 5px;

    white-space: nowrap;

    &--deleted {
      color: $tintGrey;
    }
  }

  &__actions-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    gap: 35px 0;
  }

  &__price {
    @include commonText(500);
    color: $textBlack;
    padding: 0 5px;

    &--second {
      margin-left: 33px;
    }
  }

  &__cart-button {
    width: 163px;

    &--deleted {
      background-color: $tintGrey;
    }
  }

  &__action-button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 29px;

    @include commonText(500);
    color: $textBlack;

    & > span {
      margin-left: 6px;
    }

    &--desktop {
      margin-top: 50px;
    }

    &--mobile {
      margin-bottom: 35px;
    }
  }

  &__action-button-img {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 29px;
    height: 29px;
  }

  @include desktop {
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 0 40px;

    &__info {
      display: grid;
      grid-template-columns: minmax(130px, 235px) 1fr;
      align-items: center;
    }

    &__title {
      text-align: start;
      margin-bottom: 4px;
    }

    &__vendor-code {
      margin-bottom: 0;
    }

    &__actions-wrapper {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
  }
}