@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 177px;
    margin-bottom: 73px;
  }
}

.root {
  display: flex;
  flex-direction: column;

  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    padding: 0 100px;
  }
}

.info {
  display: flex;
  flex-direction: column;

  @include customizableText(400, 18px, 28px);

  margin-bottom: 73px;

  &__title {
    @include customizableText(500, 18px, 21px);
    margin-bottom: 15px;
    color: $textBlack;

    @include tablet {
      @include customizableText(500, 20px, 24px);
    }

    @include desktop {
      @include customizableText(500, 24px, 28px);
      margin-bottom: 25px;
    }
  }

  &:nth-child(4) {
    margin: 0;
  }

  &:last-child {
    > p {
      margin-bottom: 12px;
    }
  }
}

.title--margin-top--0 {
  @include customizableText(500, 18px, 21px);
  margin: 0;
  margin-bottom: 20px;
  color: $primary;

  @include tablet {
    @include customizableText(500, 20px, 24px);
  }

  @include desktop {
    @include customizableText(500, 24px, 28px);
    margin-bottom: 25px;
  }
}

.title {
  @include customizableText(500, 18px, 21px);
  margin-bottom: 20px;
  color: $primary;

  @include tablet {
    @include customizableText(500, 20px, 24px);
  }

  @include desktop {
    @include customizableText(500, 24px, 28px);
    margin-bottom: 25px;
  }

  & > img {
    height: 16px;
    width: 14px;

    margin-right: 10px;

    @include tablet {
      height: 26px;
      width: 24px;

      margin-right: 20px;
    }
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;

  @include customizableText(400, 16px, 21px);

  @include tablet {
    @include customizableText(400, 20px, 24px);
  }

  @include desktop {
    @include customizableText(400, 24px, 28px);
    margin-bottom: 60px;
  }

  p > span {
    margin-right: 5px;
  }
}

.strong-text {
  font-weight: 500;
}

.phone_number {
  margin-bottom: 20px;

  @include customizableText(500, 14px, 21px);

  margin-bottom: 10px;

  @include tablet {
    @include customizableText(500, 16px, 24px);
  }

  @include desktop {
    @include customizableText(500, 18px, 29px);
    margin-bottom: 20px;
  }

  &:nth-child(2) {
    margin: 0;
    margin-bottom: 12px;
  }
}

.sub_content {
  margin-bottom: 25px;
}

.special_offer {
  @include customizableText(500, 14px, 24px);

  @include tablet {
    @include customizableText(500, 18px, 29px);
  }
}

.free-from {
  @include customizableText(500, 14px, 24px);
  margin-top: 20px;

  @include tablet {
    @include customizableText(500, 18px, 29px);
  }
}

.item_link {
  color: $primary;
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}