@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.properties-list {
  padding: 0 12px;

  &__item {
    display: grid;
    grid-template-columns: minmax(auto, 100px) 13px 1fr;
    @include customizableText(500, 14px, 16px);
    color: $textBlack;
    padding-bottom: 8px;
    list-style-type: none;

    :first-child {
      text-transform: capitalize;
    }
  }

  &__item-separator {
    height: 6px;
    box-shadow: inset 0px -1px $textLighterGrey;
  }

  &__item-values {
    margin-left: 17px;
  }

  @include desktop {
    padding: 0;

    &__item {
      grid-template-columns: minmax(100px, 200px) 70px 1fr;

      @include customizableText(500, 16px, 24px);

      padding-bottom: 13px;
    }

    &__item-separator {
      height: 12px;
      margin: 0 5px;
    }
  }
}

.description {
  @include customizableText(500, 16px, 24px);
  color: $textBlack;

  text-align: center;
  padding: 35px 0;

  @include desktop {
    text-align: start;
    padding: 25px 0 40px;

    &-empty {
      margin: 40px 0;
      width: 100%;
      text-align: center;
      @include customizableText(500, 18px, 28px);
    }
  }
}
