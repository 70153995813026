@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 177px;
    margin-bottom: 73px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  margin-bottom: 100px;

  @include customizableText(400, 16px, 24px);

  @include tablet {
    @include customizableText(400, 18px, 28px);
  }

  @include desktop {
    padding: 0 100px;
    margin-bottom: 180px;
  }

}

.info {
  display: flex;
  flex-direction: column;

  &__list {
    > li {
      list-style: disc;
 
      &::marker {
        font-size: 0.5em;
      }
    }
  }
}

.rules {

  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0 25px;

    @include desktop {
      padding: 0;
    }

    > li {
      display: flex;
      flex-direction: column;
      position: relative;
      counter-increment: item;
      margin-bottom: 0.6em;

      > h3 {

        @include customizableText(500, 18px, 21px);
        margin: 0;
        margin-top: 40px;
        margin-bottom: 40px;
      
        @include tablet {
          @include customizableText(500, 20px, 24px);
        }
      
        @include desktop {
          @include customizableText(500, 24px, 28px);
          margin-top: 73px;
          margin-bottom: 73px;
        }

        &::before {
          content: counters(item, ".") ". ";
          margin-right: 5px;
        }
      }

       > ol {
        padding: 0px;
        > li {
          position: relative;
          margin: 0;

          &::before {
            content: counters(item, ".") ". ";
            position: absolute;
            left: -40px;
          }
        }
       }
    }
  }

  &__vendor_details {
    > li {
      @include customizableText(500, 18px, 29px);

      @include tablet {
        @include customizableText(500, 20px, 29px);
      }

      @include desktop {
        @include customizableText(500, 24px, 38px);
      }
    }
  }
}

.title {
  @include customizableText(500, 20px, 30px);
  align-self: center;
  margin-bottom: 20px;
  color: $textBlack;

  @include tablet {
    @include customizableText(500, 28px, 30px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);
    margin-bottom: 73px;
  }

  &:first-of-type {
    text-align: center;
  }
}


.strong-text {
  font-weight: 500;
}

.item_link {
  color: $primary;
}

.list_item--markered {
  list-style: disc;
  margin-left: 10px;
  &::marker {
    font-size: 0.5em;
  }
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

