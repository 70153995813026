@import "/src/styles/scss/breakpoints";

.root {
  width: 100%;
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
}

.follow {
  display: none;
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1;
  transform: translate(42px, 18px) rotate(180deg);
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.placeholder {
  margin-top: 70px;
}
