@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  position: fixed;
  top: 90px;
  left: 50%;

  width: calc(100% - 100px);
  height: calc(100vh - 130px);

  border-radius: 10px;
  padding: 90px 80px;
  gap: 0 125px;

  background-color: $white;
  box-shadow: 0px 1px 20px 12px rgba(0, 0, 0, 0.25);
  transform: translateX(-50%);
  z-index: 1001;

  @include mobile {
    padding: 50px 30px 40px;
    flex-direction: column;
  }
  @include tablet {
     padding: 60px 50px 40px;
     flex-direction: column;
  }
  @include desktop {
    gap: 0 80px;
    padding: 70px 60px 50px;
    flex-direction: row;
  }
  @include largeDesktop{
    gap: 0 125px;
    padding: 90px 80px;
  }
}

.cross-icon {
    position: absolute;
    top: 17px;
    right: 37px;
  
    width: 27px;
    height: 27px;
}

.background {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);

  z-index: 1000;
}