.root {
  display: flex;
}

.tab {
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 35px;
    height: 35px;

    -webkit-tap-highlight-color: transparent;
  }
}