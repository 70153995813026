@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  max-width: 493px;
  width: 100%;

  padding: 0 20px;

  @include tablet {
    justify-content: center;
    align-items: flex-start;

    padding: 0;

    &:nth-child(2) {
      margin: 0 15px;
    }
  }

  @include desktop {
    &:nth-child(2) {
      margin: 0 20px;
    }
  }
}

.news-icon {
  max-height: 296px;
  width: 100%;
  height: 100%;

  @include tablet {
    max-width: 236px;
    max-height: 224px;
  }

  @include desktop {
    max-width: 493px;
    max-height: 440px;
  }
}

.date {
  @include additionalText();
  color: $textGrey;

  width: 100%;

  margin-bottom: 15px;
  border-bottom: 1px solid $textBlack;
  padding: 35px 0 9px;

  text-align: center;

  @include tablet {
    text-align: start;

    padding: 15px 0 10px;
  }

  @include desktop {
    padding: 27px 0 9px;
  }
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @include additionalText();
  color: $textBlack;

  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

  @include tablet {
    -webkit-line-clamp: 2;
    text-align: start;
  }
}

.read-more {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 180px;
  height: 54px;

  margin-top: 25px;

  @include tablet {
    align-self: flex-end;

    width: auto;
    height: auto;
  }

  @include desktop {
    margin-top: 66px;
  }
}