@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.list {
  display: grid;
  grid-template-rows: repeat(3, auto);
  justify-items: flex-start;
  align-items: center;

  background-color: $darkWhite;
  padding: 24px;
  gap: 18px;
  box-shadow: inset 0px -1px $lighterGrey;

  &__item-wrapper {
    gap: 18px;
    @include customizableText(500, 14px, 16px);
    color: $textBlack;
  }

  &__item {
    display: grid;
    grid-template-columns: 24px auto;
    justify-items: center;
    align-items: center;

    width: auto;
    height: auto;
    gap: 8px;
  }
}

.item {
  &__title {
    position: relative;
    @include customizableText(500, 14px, 16px);
    color: $textBlack;
    

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 1px;

      background-color: $textBlack;
    }
  }

  &__icon-geo {
    width: 18px;
    height: 20px;
    filter: brightness(0);
  }

  &__icon-personal-aria {
    width: 20px;
    height: 22px;
  }

  &__icon {
    width: 20px;
    height: 20px;

    &_pay {
      width: 22px;
      height: 24px;
    }
  }

}