@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.pagination {
  display: grid;
  grid-template-columns: auto 120px auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-top: 25px;
  padding: 0 20px;
  gap: 18px;

  @include tablet {
    justify-content: center;
    gap: 60px;
  }

  @include largeDesktop {
    justify-content: space-between;
    gap: 18px;
  }
}

.dots {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.dot {
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background-color: $secondary;

  &--active {
    background-color: $primary;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include commonText(500);
  color: $darkgrey;

  max-width: 564px;
  width: 100%;

  margin: 34px auto 20px;
  gap: 15px;

  overflow-x: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  &_button {
    padding: 9px 10px;
    white-space: nowrap;

    &--active {
      box-shadow: 0 0 0 2px $darkgrey;
      border-radius: 10px;
      transition: box-shadow 0.1s ease-in-out;
    }

    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }
  }

  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }

  @include tablet {
    margin-bottom: 107px;
  }

  @include desktop {
    max-width: 564px;
    margin: 52px auto 0;

    gap: 0;
    overflow: visible;
  }
}