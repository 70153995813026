@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.slide {
  position: relative;
  width: 100vw !important;
  height: 0;
  padding-bottom: 47.5%;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 1520px;
  border-radius: 20px !important;
  object-position: center;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  text-align: left;

  @include tablet {
    left: 25px;
  }

  @include desktop {
    left: 35px;
  }

  &__first-string,
  &__two-string {
    color: $white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__first-string {
    @include customizableUbuntuText(30px, auto, 700);

    @include tablet {
      @include customizableUbuntuText(70px, auto, 700);
    }

    @include desktop {
      @include customizableUbuntuText(90px, auto, 700);
    }

    @include largeDesktop {
      @include customizableUbuntuText(120px, auto, 700);
    }
  }

  &__two-string {
    position: relative;
    @include customizableUbuntuText(24px, auto, 700);
    left: 0;

    @include tablet {
      @include customizableUbuntuText(54px, auto, 700);
    }

    @include desktop {
      @include customizableUbuntuText(70px, auto, 700);
    }

    @include largeDesktop {
      @include customizableUbuntuText(80px, auto, 700);
    }
  }
}

.decorative-line {
  width: 100px;
  height: 2px;
  background: linear-gradient(
    90deg,
    #ffdfaa 0%,
    #a48160 34%,
    #cb9762 57%,
    #ce924f 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 12px;

  @include tablet {
    width: 180px;
    height: 3px;
    margin-top: 22px;
  }

  @include desktop {
    width: 220px;
    height: 4px;
    margin-top: 32px;
  }

  @include largeDesktop {
    width: 356px;
    margin-top: 47px;
  }
}
