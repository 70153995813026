@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.custom-title {
  @include customizableText(500, 24px, 30px);

  margin-top: 100px;
  margin-bottom: 20px;

  @include mobile {
    @include customizableText(500, 28px, 30px);
  }

  @include tablet {
    @include customizableText(500, 30px, 38px);
  }

  @include desktop {
    @include customizableText(500, 32px, 38px);

    margin-top: 177px;
    margin-bottom: 73px;
  }
}

.root {
  padding: 0 20px;
  margin-bottom: 100px;

  @include desktop {
    padding: 0 100px;
    margin-bottom: 180px;
  }
}

.list {
  display: flex;
  flex-direction: column;

  @include customizableText(400, 18px, 30px);
  padding: 0 20px;

  @include tablet {
    @include customizableText(400, 20px, 30px);
  }

  @include desktop {
    @include customizableText(400, 24px, 38px);
    padding: 0;
  }

  ::marker {
    color: $primary;
  }

  &__item {
    margin-bottom: 74px;
    > p {
      @include customizableText(400, 14px, 21px);
      padding: 5px 0;
      margin-bottom: 10px;

      @include tablet {
        @include customizableText(400, 16px, 24px);
      }

      @include desktop {
        @include customizableText(400, 18px, 29px);
        text-align: justify;

        margin-bottom: 20px;
      }
      margin-left: -25px;
      margin-bottom: 25px;
      > span {
        font-weight: 500;

        color: $primary;
      }
    }

    &:last-child {
      margin-bottom: 180px;
    }
    &_title {
      @include customizableText(500, 18px, 30px);
      margin-bottom: 15px;
      color: $primary;

      @include tablet {
        @include customizableText(500, 20px, 30px);
      }

      @include desktop {
        @include customizableText(500, 24px, 38px);
        margin-bottom: 25px;
      }
    }

    &_postscript {
      font-weight: 500;

      margin-top: 25px;

      color: $textBlack;
    }

    &_link {
      font-weight: 600;

      color: $primary;

      margin-left: 5px;
    }

    &__banks-list {
      list-style-type: " - ";

      margin-left: -20px;
      margin-bottom: 25px;

      li {
        font-weight: 500;
        color: $textBlack;

        @include customizableText(400, 14px, 21px);

        @include tablet {
          @include customizableText(400, 16px, 24px);
        }

        @include desktop {
          @include customizableText(400, 18px, 29px);
        }
      }
    }

    &__restrictions-list {
      list-style-type: none;
      margin-left: -20px;
      margin-bottom: 25px;

      @include customizableText(400, 14px, 21px);
      padding: 5px 0;
      margin-bottom: 10px;

      @include tablet {
        @include customizableText(400, 16px, 24px);
      }

      @include desktop {
        @include customizableText(400, 18px, 29px);
        text-align: justify;

        margin-bottom: 20px;
      }
    }
  }
}

.item_link {
  color: $primary;
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
