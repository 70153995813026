@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 80px;
  left: 0;
  width: 100%;
  background-color: $white;
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @include tablet {
    padding: 15px 15px;
    border-radius: 10px;
  }

  @include desktop {
    padding: 20px 20px;
  }

  @media (min-width: 1100px) and (max-width: 1440px) {
    padding: 36px 44px;
  }

  @include largeDesktop {
    padding: 36px 44px;
  }
}

.wrapper {
  position: relative;
  margin: 36px 0;
  margin-bottom: 45px;
  overflow: hidden;
  z-index: 2;
  width: 100%;
}

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;

  border-bottom: 1px solid $textLighterGrey;
  padding-bottom: 14px;
}

.title {
  @include additionalText();
  color: $textBlack;
}

.change-order {
  position: absolute;
  top: 50%;
  right: 0;
  display: none;

  @include customizableText(500, 14px, 17px);
  color: $textLightGrey;

  transform: translateY(calc(-50% - 7px));

  @include tablet {
    display: block;
  }
}

.products-wrapper {
  position: relative;
  width: 100%;

  &--top-shadow {
    &::before {
      content: "";
      position: absolute;
      left: 0;

      width: 100%;
      height: 10px;

      background: rgba(129, 129, 129, 0.79);
      filter: blur(20px);
      z-index: 1;
    }
  }
  &--bottom-shadow {
    &::after {
      content: "";
      position: absolute;
      left: 0;

      width: 100%;
      height: 30px;

      background: rgba(129, 129, 129, 0.79);
      filter: blur(20px);
      z-index: 1;
    }
  }
}

.line {
  border-bottom: 1px solid $textLighterGrey;
  margin-bottom: 30px;
  height: 1px;
  width: 100%;
}

.products {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 380px;
  gap: 20px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @include desktop {
    padding-right: 21px;
  }

  &__title {
    @include commonText(500);
    color: $textBlack;
    text-align: center;
    transform: translateX(20px);
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  & > :last-child {
    padding: 0;
  }
}

.track {
  right: 0;
}

.promocode {
  &__button {
    max-width: 336px;
    width: 100%;
  }
}

.data-wrapper {
  width: 100%;
  padding: 45px 0;
  gap: 10px;

  @include desktop {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    justify-items: flex-start;
  }
}

.user-data,
.price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 15px;
  width: 100%;

  &__info-line {
    flex-wrap: wrap;
  }

  & > p {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    margin-bottom: 23px;

    :first-child {
      white-space: nowrap;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
  @include desktop {
    margin-top: 0px;
  }
}

.price {
  white-space: nowrap;
}

.checkout-button {
  width: 100%;
  margin-top: 36px;
}

.date {
  @include commonText(500);
  color: $textBlack;

  & > span {
    color: $primary;
  }
}

.warning {
  @include customizableText(500, 12px, 14px);
  color: $textLightGrey;
  margin-bottom: 45px;
}

.offer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  @include commonText(500);
  color: $textBlack;

  &__checkbox {
    margin-right: 15px;
  }

  &__description {
    width: 100%;
  }

  &__link {
    color: $textBlack;
    text-decoration: underline !important;
    margin: 0 3px;
  }
}

.order-item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  text-align: center;
  min-height: 270px;

  @media (min-width: 600px) {
    grid-template-columns: 100px 1fr;
    gap: 0;
    min-height: 120px;
  }

  @include tablet {
    grid-template-columns: 100px 2fr 1fr;
  }

  @include desktop {
    grid-template-columns: 100px 1fr;
    min-height: 200px;
  }

  &__image {
    grid-row: 1 / span 2;
    width: 100%;
    height: auto;
  }
}
