@import "../../../../../../../styles/scss/variables";
@import "../../../../../../../styles/scss/mixins/typography";

.root {
  position: relative;
  width: 100%;

  &--top-shadow {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 20px;

      background-color: $gainsboro;
      filter: blur(15px);
      z-index: 10;
    }
  }
  &--bottom-shadow {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 20px;

      background-color: $gainsboro;
      filter: blur(15px);
      z-index: 10;
    }
  }
}

.providers-addresses {
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollbar {
  width: 100%;
}

.list {
  width: 100%;

  &__title {
    @include commonText(500);
    color: $textBlack;
    margin-left: 22px;
    margin-bottom: 16px;
  }

  &__button {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    padding: 5px 0px;

    &-provider {
      @include customizableText(600, 18px, 20px);
      font-family: "Montserrat";
      &:first-letter {
        text-transform: uppercase;
      }
    }

    &-title {
      margin-top: 10px;
      @include customizableText(500, 16px, 18px);
      color: $textBlack;
      text-align: start;
    }

    &-number {
      margin-top: 5px;
      @include customizableText(400, 14px, 16px);
    }
  }

  &__button-icon {
    width: 13px;
    height: 15px;
    margin-right: 15px;
  }
}
