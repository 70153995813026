@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
}

.title {
  @include commonText(500);
  color: $textBlack;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 494px;
  width: 100%;
  margin: 85px 0;
  padding: 0 20px;

  &__title {
    margin-bottom: 40px;
  }

  &__input {
    margin-bottom: 25px;

    & > input {
      text-align: center;
    }
  }

  &__button {
    width: 100%;
  }

  @include desktop {
    align-items: flex-start;

    margin: 75px 0;
    padding: 0;

    &__title {
      margin-bottom: 22px;
    }

    &__input {
      margin-bottom: 22px;

      & > input {
        text-align: start;
      }
    }
  }
}

.social-media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 0 20px;

  &__title {
    margin-bottom: 41px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 40px;
  }

  &__type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 109px;
    margin-bottom: 40px;
  }

  &__icon-wrapper {
    position: relative;
    width: 64px;
    height: 64px;

    background-color: $white-smoke;
    border-radius: 100%;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 36px;
    height: 36px;
  }

  &__subtitle {
    @include commonText(500);
    color: $textLightGrey;

    margin-top: 14px;
  }

  &__attach-button {
    @include commonText(500);
    color: $primary;

    border-bottom: 1px solid $primary;
    padding-bottom: 2px;
  }

  @include desktop {
    align-items: flex-start;

    padding: 0;

    &__items {
      align-items: flex-start;
    }

    &__item {
      flex-direction: row;
      margin-bottom: 47px;
    }

    &__type {
      margin-right: 22px;
      margin-bottom: 0;
    }
  }
}