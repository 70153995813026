@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include customizableText(500, 28px, 34px);
  color: $textBlack;
}

.delivery-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    @include customizableText(500, 28px, 34px);
    color: $textBlack;
    grid-column: 1 / 5;
  }

  &__provider {
    max-width: 300px;
    width: 100%;
    @include commonText(500);
    color: $textBlack;
    margin-top: 31px;
    margin-bottom: 15px;
  }

  &__schedule {
    max-width: 200px;
    width: 100%;
    @include customizableText(500, 14px, 16px);
    color: $textBlack;
    margin-bottom: 15px;
  }

  &__address-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 300px;
    width: 100%;
  }

  &__address-icon {
    width: 13px;
    height: 15px;
  }

  &__address-description {
    @include customizableText(500, 14px, 16px);
    color: $textBlack;
    margin-left: 10px;
  }
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 150px;
}

.select-button {
  padding: 9px 80px;
}

.additional-button {
  @include commonText(500);
  color: $textBlack;
  margin-top: 30px;
  text-decoration: underline;
}
