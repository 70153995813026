@import "../../../../../../../../styles/scss/variables";
@import "../../../../../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: 0 auto;
  padding: 19px 0 30px;
}

.title {
  @include commonText(500);
  color: $textLightGrey;
  margin-right: 18px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 37px;

  &_title {
    @include commonText(500);
    margin-right: 9px;
  }

  &_arrow {
    width: 8px;
    height: 14px;
    transform: rotate(90deg);
  }
}

.description {
  @include commonText(500);
}

.review-number {
  @include commonText(500);
}
