@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  @include tablet {
    padding: 0 5px 0 5px;
  }

  @include desktop {
    padding: 0 20px 0 10px;
  }

  @include largeDesktop {
    padding: 0 10px 0 60px;
  }
}

.products-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  width: 100%;

  @include mobile {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  @media (min-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @include tablet {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  @include desktop {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
  }

  @include largeDesktop {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    grid-gap: 20px;
  }

  &--empty {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
  }

  &__title {
    @include additionalText();
  }
}

.card_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 350px;

  @include tablet {
    height: 400px;
  }

  @include desktop {
    height: 520px;
  }

  @include largeDesktop {
    height: 600px;
  }
}

.show-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  &__skeleton {
    max-width: 231px;
    width: 100%;
    height: 44px;

    margin-top: 80px;
    margin-bottom: 45px;
  }

  &__button {
    max-width: 231px;
    width: 100%;

    margin-top: 80px;
    margin-bottom: 45px;
  }

  &__pagination {
    margin-top: 80px;
  }

  @include desktop {
    margin-top: 0;
  }
}
