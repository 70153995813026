@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 400px;
}

.input {
  height: 45px;
  width: 100%;
  padding: 3px 5px;

  @include commonText(500);
  color: $textBlack;
  text-align: left;

  border-radius: 5px 0px 0px 5px;
  border: 1px solid $textBlack;

  &::placeholder {
    @include customizableText(500, 14px, 14px);
    color: $textBlack;

    text-align: center;

    @include mobile {
      @include customizableText(500, 16px, 16px);
    }
  }

  &--not-valid {
    border: 1px solid red;
  }

  &--success {
    background-color: rgba(87, 86, 86, 0.171);
  }

  @include mobile {
    padding: 10px 20px;
  }
}

.button {
  width: 178px;
  height: 45px;
  border-radius: 0px 5px 5px 0px;

  &:active {
    scale: 0.95;
  }
}

.success {
  @include customizableText(500, 12px, 12px);
  color: rgb(11, 182, 11);

  position: absolute;
  top: -26px;
  left: 0;
}

.error {
  @include customizableText(500, 12px, 12px);
  color: red;

  position: absolute;
  top: -26px;
  left: 0;
}
