@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";
@import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.contacts {
  position: absolute;
  top: 50px;
  // left: 50%;
  right: 100%;
  transform: translateX(50%);

  width: 150px;

  opacity: 0;
  background-color: $athensGray;
  box-shadow: 0px 1px 20px 6px rgba(0, 0, 0, 0.25);

  &-enter-done {
    opacity: 1;
    transition: all 150ms ease;
  }

  @media (min-width: 500px) and (max-width: 800px) {
      right: 50%;
  }

  @include tablet {
    right: 50%;
  }

  @include desktop {
    right: 50%;
  }
}

.list {
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  &__item-element {
    white-space: nowrap;
    @include commonText(500);
    color: $lighterBlack;
    cursor: pointer;
    border-bottom: 1px solid $lighterBlack;
  }
}