@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  max-width: 1537px;
  width: 100%;

  margin: 0 auto;
  padding: 57px 20px 85px;

  @include desktop {
    padding: 20px 10px 100px;
  }

  &__head {
    width: 100%;
    padding: 0px;

    @include desktop {
      padding: 0px 40px;
    }
  }
}

.breadcrumbs {
  width: 100%;
  padding-top: 30px;

  & > :last-child a {
    color: $textBlack;
  }
}

.title {
  align-self: center;
  @include additionalText();
  color: $primary;
  padding: 30px 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @include desktop {
    @include bigTitle;
    color: $textBlack;
    padding: 20px 0;
  }
}

.content-wrapper {
  display: grid;
  align-items: flex-start;
  width: 100%;

  &--empty {
    display: flex;
    width: 100%;
  }

  @include tablet {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include desktop {
    display: grid;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: 285px calc(100% - 350px);
  }
}

.selected-filters {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: flex-start;
  align-items: center;

  width: 100%;
  padding-bottom: 35px;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &--gereral-page {
    margin-top: 50px;
  }

  @include desktop {
    align-items: flex-start;
    padding-bottom: 130px;
  }
}

.seo {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}