@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.wrapper {
  position: relative;
  width: 100%;
  padding-bottom: calc(700 / 730 * 100%);
  overflow: hidden;
  border-radius: 20px;

  @include largeDesktop {
    flex: 1;
    padding-bottom: calc(510 / 1200 * 100%);
  }

  @media (min-width: 1520px) {
    width: 510px;
    height: 700px;
    padding-bottom: 0;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
