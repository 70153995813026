@import "../../../styles/scss/variables";
@import "../../../styles/scss/mixins/typography";
// @import "/src/styles/scss/breakpoints";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  @include commonText(500);
  color: $textBlack;
}

.title {
  padding-right: 5px;

  // @include desktop {
  //   white-space: wrap;
  // }
}

.subtitle {
  color: $primary;
}