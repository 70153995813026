@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  &__figure {
    width: 80px;
    height: 80px;

    margin-bottom: 50px;
    border-top: 3px solid $primary;
    border-radius: 50%;

    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__title {
    @include bigTitle();
  }
}
