@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  @include desktop {
    padding-top: 20px;
    align-items: flex-start;
  }
}

.properties-list {
  padding: 0 12px;

  &__item {
    display: grid;
    grid-template-columns: minmax(auto, 100px) 13px 1fr;

    @include customizableText(500, 12px, 12px);
    color: $textBlack;

    padding-bottom: 8px;
    list-style-type: none;

    :first-child {
      text-transform: capitalize;
    }
  }

  &__item-separator {
    height: 6px;
    box-shadow: inset 0px -1px $textLighterGrey;
  }

  &__item-values {
    margin-left: 17px;
  }

  @include desktop {
    padding: 0;

    &__item {
      grid-template-columns: minmax(100px, 200px) 70px 1fr;

      @include customizableText(500, 16px, 24px);

      padding-bottom: 13px;
    }

    &__item-separator {
      height: 12px;
      margin: 0 5px;
    }
  }
}

.description-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  gap: 75px;

  box-shadow: inset 0px -1px $textLighterGrey;

  overflow-x: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__title {
    @include customizableText(500, 20px, 24px);
    color: $textBlack;

    padding-bottom: 9px;

    white-space: nowrap;
    cursor: pointer;

    &--active {
      box-shadow: inset 0px -2px $textBlack;
      transition: box-shadow 0.3s ease-in-out;
    }
  }

  @include desktop {
    gap: 55px;

    &__title {
      @include bigTitle();
    }
  }
}

.description {
  @include customizableText(500, 16px, 24px);
  color: $textBlack;

  text-align: center;
  padding: 35px 0;

  @include desktop {
    text-align: start;
    padding: 25px 0 40px;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  width: 100%;

  margin-top: 27px;
  overflow: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  gap: 13px;

  & > a {
    white-space: nowrap;
  }

  @include desktop {
    flex-wrap: wrap;
    gap: 10px 13px;
    margin-top: 50px;
  }
}
