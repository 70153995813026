@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.quantity-selector {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  justify-content: center;
  align-items: center;

  &__action-button {
    position: relative;
    z-index: 1;
    width: 35px;
    height: 28px;
    border-radius: 5px;
    border: 2px solid $greyish;
    @include customizableText(500, 16px, 19.5px);
    color: $textBlack;

    // Увеличение области клика кнопок для удобства
    &::before {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      z-index: -1;
      cursor: pointer;
    }

    &:active {
      animation: scaleDown 0.2s ease forwards;
    }
  }

  &__amount {
    text-align: center;
    min-width: 50px;
    white-space: nowrap;
    @include customizableText(500, 16px, 19.5px);
    color: $textBlack;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
