@import "../../../../../../styles/scss//variables";
@import "../../../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 660px;
  width: 100%;

  margin: 0 auto;
  padding-top: 70px;
}

.faq_content {
  padding-bottom: 43px;
}

.faq_button {
  width: 100%;
}