@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.container {
  width: calc(100% - 40px);
  border-radius: 20px;
  overflow: hidden;
  background: linear-gradient(180deg, #F7F7F7 0%, #EAEAEA 100%);

  @include tablet {
    width: calc(100% - 80px);
  }

  @include largeDesktop {
    display: flex;
    flex-direction: row-reverse;
    width: calc(100% - 20px);
    height: 330px;
  }

  @media (min-width: 1520px) {
    width: 1520px;
  }
  
}

.image {
  width: 100%;
  height: 200px;
  object-fit: cover;

  @include tablet {
    height: 430px;
  }


  @include largeDesktop {
    width: 600px;
    height: 330px;
  }

  @media (min-width: 1520px) {
    width: 661px;
  }
}

.text {
  padding: 20px;
  text-align: left;

  &__title {
    @include customizableText(800, 32px, auto);
    color: #191B38;
    margin-bottom: 10px;

    @include tablet {
      @include customizableText(800, 40px, auto);
    }
  }

  &__description {
    @include customizableText(500, 18px, auto);
    color: $textBlack;

    @include tablet {
      @include customizableText(500, 24px, auto);
    }
  }

  @include tablet {
    padding: 40px;
  }

  @include largeDesktop {
    padding: 76px 0 106px 30px;
  }

  @media (min-width: 1520px) {
    padding: 106px 0 106px 80px;
  }  
}