@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;

  background-color: $white;
  border-radius: 10px;
  padding: 15px 0 30px;
  width: 100%;

  @include desktop {
    padding: 36px 0;
  }
}

.empty-cart {
  align-self: center;

  @include commonText(500);
  color: $textBlack;
  text-align: center;

  padding-top: 15px;

  @include desktop {
    @include additionalText();
    text-align: start;
    padding-top: 36px;
  }
}
