// "Montserrat", sans-serif - из ссылок напрямую с head
@mixin bigTitle {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
}

@mixin additionalText {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@mixin commonText($fontWeight) {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: 16px;
  line-height: 20px;
}

@mixin customizableText($fontWeight, $fontSize, $lineHeight) {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

// Inter через прямую ссылку

@mixin descriptionTextBig {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

@mixin descriptionTextMedium($fontWeight) {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: 12px;
  line-height: 15px;
}

@mixin descriptionTextSmall {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

// Yeseva One

@mixin customizableYesevaText($fontSize, $lineHeight) {
  font-family: "Yeseva One", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: $fontSize;
  line-height: $lineHeight;
}

// Ubuntu

@mixin customizableUbuntuText($fontSize, $lineHeight, $fontWeight) {
  font-family: "Ubuntu", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}