@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 1294px;
  width: 100%;

  border-bottom: 1px solid $textLighterGrey;
  padding-bottom: 85px;

  @include desktop {
    padding-bottom: 75px;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @include commonText(500);
  color: $textBlack;

  width: 100%;
  padding: 0 20px;

  &:first-child {
    margin-bottom: 30px;
  }

  &__title {
    flex-direction: column;
    justify-content: center;
    width: 100%;

    gap: 15px 0;

    &--active {
      color: $primary;
    }

    &:nth-child(3n + 3) {
      max-width: 375px;
      width: 100%;
    }

    & > span {
      text-align: center;
    }
  }

  &__track-number {
    border-bottom: 1px solid $primary;
  }

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    padding: 0;

    &__title {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      gap: 0;

      &:first-child {
        margin-bottom: 66px;
      }

      &:nth-child(2), &:nth-child(5) {
        padding: 0 10px;
      }

      & > span {
        text-align: start;
      }
    }
  }
}