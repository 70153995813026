@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: flex-start;

	position: relative;
	max-width: 365px;
	width: 100%;

	&__labels {
		display: flex;
		justify-content: space-around;
		align-items: center;

		width: 100%;
	}

	&_img-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		width: 112px;
		height: 112px;

		margin: 10px 0;
    overflow: hidden;

		&-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
			opacity: 0.3;
			animation: 2s animation linear infinite;
		}

		@keyframes animation {
			0% {
				opacity: 0.2;
			}
			40% {
				opacity: 0.9;
			}
			100% {
				opacity: 0.2;
			}
		}

		@include tablet {
			width: 150px;
			height: 150px;
		}

		@include desktop {
			width: 200px;
			height: 200px;

			margin: 15px 0;
		}

		@include largeDesktop {
			width: 300px;
			height: 300px;

			margin: 25px 0;
		}
	}
}
