@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.scrollbar {
  position: relative;
  width: 100%;

  @media (min-width: 500px) {
    width: 500px;
  }
}

.list {
  max-height: 280px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  &--single {
    grid-template-columns: repeat(1, 124px);
  }

  &--top-shadow {
    &::before {
      content: "";
      position: absolute;
      top: -14px;
      left: -4px;

      width: calc(100% + 8px);
      height: 20px;

      background: rgba(223, 223, 223, 0.79);
      filter: blur(9px);
    }
  }

  &--bottom-shadow {
    &::after {
      content: "";
      position: absolute;
      bottom: -14px;
      left: -4px;

      width: calc(100% + 8px);
      height: 20px;

      background: rgba(223, 223, 223, 0.79);
      filter: blur(9px);
    }
  }
}

.offer--not-available {
  @include commonText(500);
  color: $textBlack;
}
