@import "../../../../../../../styles/scss/variables";
@import "../../../../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  padding: 8px 0;

  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.input {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.label {
  @include commonText(500);
  color: $textBlack;
  padding-left: 20px;

  user-select: none;
}

.info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  :hover ~ .info__text {
    visibility: visible;
  }
}

.info {
  &__icon {  
    width: 16px;
    height: 16px;
  
    margin-left: 16px;
    color: $lightGrey;
  }

  &__text {
    position: absolute;
    bottom: 20px;
    right: 0;
    visibility: hidden;

    @include customizableText(500, 12px, 14px);
    color: $textBlack;

    width: 165px;
    border-radius: 10px;
    border: 1px solid $lightGrey;
    padding: 10px;

    background-color: $white;
  }
}