@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 40px;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.logo {
  max-width: 147px;
  max-height: 144px;
  width: 100%;
  height: 100%;

  margin-bottom: 25px;

  @include desktop {
    margin-bottom: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    width: 70%;

    @include commonText(500);
    color: $primary;

    @include tablet {
      text-align: center;
    }

    @include desktop {
      text-align: left;
    }
  }

  &__properties {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    width: 70%;

    @include customizableText(500, 15px, 20px);
    color: $textLightGrey;
    margin-bottom: 17px;

    @include tablet {
      text-align: center;
    }

    @include desktop {
      text-align: left;
    }
  }

  &__vendor-code {
    @include customizableText(500, 12px, 15px);
    color: $textLightGrey;
    margin-bottom: 17px;
  }

  &__price-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @include commonText(500);
    color: $textBlack;

    width: 100%;
    gap: 0 15px;
  }

  &__main-price {
    color: $textLighterGrey;
    text-decoration: line-through;
    white-space: nowrap;
  }

  &__sale-price {
    color: $primary;
    white-space: nowrap;
  }

  @include tablet {
    max-width: 400px;
    min-width: 400px;

    &__price-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      
      gap: 0;
    }

    &__main-price {
      margin-right: 10px;
    }

    &__item-amount {
      white-space: nowrap;
      margin-left: 10px;
    }
  }

  @media (min-width: 450px) and (max-width: 809px) {
    max-width: 400px;
    min-width: 400px;

    &__price-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      
      gap: 0;
    }

    &__main-price {
      margin-right: 10px;
    }

    &__item-amount {
      margin-left: 10px;
    }
  }

  @include desktop {
    max-width: 450px;
    min-width: 450px;

    &__price-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      gap: 0;
    }

    &__main-price {
      margin-right: 10px;
    }

    &__item-amount {
      margin-left: 10px;
    }
  }
}