@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.dropdown {
  border-bottom: 1px solid $textLighterGrey;
}

.disable {
  pointer-events: none;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include commonText(500);
  color: $textBlack;

  width: 100%;
  height: 100%;

  padding: 14px 0;

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include desktop {
    justify-content: flex-start;
  }
}

.plus-icon-wrapper {
  position: absolute;
  top: 50%;
  right: 0;

  transform: translateY(-50%);
}

.plus-icon {
  width: 12px;
  height: 12px;

  transform: rotate(0deg);
  transition: transform 0.2s ease;

  &--rotated {
    transform: rotate(-45deg);
  }
}

.help {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__text {
    @include customizableText(500, 12px, 17px);
    color: $textBlack;

    padding-top: 6px;
    padding-bottom: 20px;
  }

  @include desktop {
    align-items: flex-start;

    &__text {
      padding-bottom: 25px;
    }
  }
}

.social-media {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 12px;

  &__icon {
    > img {
      width: 36px;
      height: 36px;
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }

  @include desktop {
    padding-bottom: 15px;
  }
}
